import { Help } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/es'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomSelectMultiple } from '../../components/CustomSelect'
import FancyInput from '../../components/FancyInput'
import MessageManager from '../../components/MessageManager'
import Progress from '../../components/Progress'
import StepsBtn from '../../components/StepsBtn'
import TextInformation from '../../components/TextInformation'
import { useApp } from '../../hooks/useApp'
import '../../i18n'
import { getAsociado, reportBookingCapacity } from '../../services/Services'
import { CONSTANT } from '../../utils/Constants'
import {
  convertTo24HourFormat,
  filterHours,
  formatSingleHourTo12Hour,
  formatTo12Hour
} from '../../utils/FilterHours'
import { formatNumber, formatterMoney } from '../../utils/commons'

const InputContainerStiled = styled(Box)(({ theme }) => ({
  gridTemplateColumns: 'repeat(2, 1fr)',
  alignItems: 'center',
  justifyItems: 'start',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    '& > p': {
      gridColumn: '1/3'
    },
    '&.inputColegiate': {
      gridTemplateColumns: '100%'
    }
  }
}))

const BoxSchedulesStyled = styled(Box)(({ theme }) => ({
  '& .MuiPickersCalendarHeader-root ': {
    justifyContent: 'space-between',
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2)
    }
  },
  '& .MuiPickersCalendarHeader-labelContainer': {
    height: 60,
    maxHeight: 60,
    marginRight: 0
  },
  '& .MuiPickersCalendarHeader-label': {
    fontSize: 20,
    height: 35,
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'calc(10px + 2vw)',
      height: 22,
      marginRight: 0
    }
  },
  '& .MuiCalendarPicker-root': {
    width: 600,
    maxWidth: '100%',
    maxHeight: 550,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 380
    }
  },
  '& .MuiTypography-caption': {
    width: 61,
    height: 25,
    margin: 0,
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(10px + 8vw)'
    }
  },
  '& .MuiDayPicker-slideTransition': {
    minHeight: 200,
    [theme.breakpoints.down('sm')]: {
      minHeight: 220
    }
  },
  '& .MuiPickersDay-root': {
    width: 47,
    height: 35,
    fontSize: 14,
    border: 'solid 5px #fff',
    borderLeftWidth: 7,
    borderRightWidth: 7,
    borderTopWidth: 2,
    borderBottomWidth: 2,
    margin: '0 8px',
    '&.MuiPickersDay-today:not(.Mui-selected)': {
      border: 'solid 5px #fff',
      borderLeftWidth: 12,
      borderRightWidth: 12,
      backgroundColor: '#00000015'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(5px + 8vw)',
      height: 40,
      borderLeftWidth: 3,
      borderRightWidth: 3,
      '&.MuiPickersDay-today:not(.Mui-selected)': {
        borderLeftWidth: 3,
        borderRightWidth: 3
      }
    }
  },
  '& .MuiCalendarOrClockPicker-root>div': {
    maxHeight: 440,
    width: 650,
    maxWidth: '100%'
  },
  '& .MuiPickerStaticWrapper-content': {
    minWidth: 'auto'
  },
  '& .MuiPickersArrowSwitcher-spacer': {
    [theme.breakpoints.down('sm')]: {
      width: 4
    }
  }
}))

const Schedules = ({
  areasData,
  formData,
  setFormData,
  handleBack,
  handleNext,
  colegial,
  setColegial,
  setDisabledFullday,
  disabledFullday,
  haveAssociated,
  currency
}: any) => {
  const { language, iDCompany, setErrorMsg, errorMsg, resetErrorMsg } = useApp()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down('md'))
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  const [valueDate, setValueDate] = useState<Dayjs | null>(
    formData.bookingDay ? dayjs(formData.bookingDay) : null
  )
  const [selectedHours, setSelectedHours] = useState<string[]>(
    formData.hours ? formData.hours.map(formatTo12Hour) : []
  )
  const [hoursAvailable, setHoursAvailable] = useState<any>(undefined)
  const [hoursAvailableCapacity, setHoursAvailableCapacity] = useState<any>(null)
  const [checkBox, setCheckBox] = useState<boolean>(
    formData?.isColegial ? formData.isColegial : false
  )
  const [checkBoxFullDay, setCheckBoxFullDay] = useState<boolean>(
    formData.fullDay ? formData.fullDay : false
  )
  const [colegialnro, setColegialnro] = useState(formData.nroColegial ? formData.nroColegial : '')
  const [errorColegial, setErrorColegial] = useState<string | boolean>(' ')
  const [areaDetail, setAreaDetail] = useState<any>(null)
  const [idArea, setIdArea] = useState<any>(null)
  const [areaCapacity, setAreaCapacity] = useState<any>(null)
  const [loader, setLoader] = useState(false)
  const [hourValidationAlert, setHourValidationAlert] = useState(false)
  const [selectOpen, setSelectOpen] = useState(false)

  useEffect(() => {
    console.log(iDCompany && iDCompany?.body.content[0].hourEarlier)

    setAreaDetail(
      areasData?.body[formData.area]?.areaDetailList.find((item: any) => {
        return item.description === formData.desc
      })
    )
    const idArea = areasData?.body[formData.area]?.id

    const capacity = areasData?.body[formData.area]?.capacity

    if (idArea) {
      setIdArea(idArea)
    }

    if (capacity) {
      setAreaCapacity(capacity)
    }

    // eslint-disable-next-line
  }, [])


  // useEffect(() => {
  //   if (valueDate && areaDetail) {
  //     console.log(valueDate)
  //     console.log(iDCompany)
  //     console.log(areaDetail)
  //     getHours(valueDate)
  //   }
  //   // eslint-disable-next-line
  // }, [areaDetail])

  //const areaDetail = areasData?.body[formData.area]?.areaDetailList[formData.desc]
  const hourValidation = checkBoxFullDay ? false : selectedHours.length === 0
  const colegiateValidation = formData.isColegial
    ? colegialnro.length === 0 || hourValidation
    : false

  const disabledNextBtn =
    !formData.bookingDay ||
    hourValidation ||
    colegiateValidation ||
    ((areaCapacity || areaDetail?.hasCapacity) &&
      (!formData.spaces ||
        formData.spaces === '' ||
        formData.spaces <= 0 ||
        formData.spaces > (areaCapacity ? areaCapacity : areaDetail?.capacity)));

  useEffect(() => {
    document.documentElement.scrollTop = 0
  }, [])

  console.log(areaDetail)

  const getHours = async (dateValue: Dayjs | null) => {
    try {
      if (!dateValue) {
        // Manejar el caso en el que no se proporciona una fecha
        return;
      }

      const valueDayWeek: number = Number(dateValue.format('d'));
      const isWeekend: boolean = valueDayWeek === 0 || valueDayWeek === 6;

      // Llamar al endpoint para obtener la capacidad de reserva
      const capacityData = await reportBookingCapacity(
        iDCompany?.body.content[0].id,
        dateValue.format('YYYY-MM-DD'),
        idArea,
        areaCapacity && areaCapacity > 0 ? undefined : areaDetail.id,
      );

      // Verificar si hay datos de capacidad disponibles
      if (capacityData && capacityData.content) {
        // Filtrar y mapear los datos de capacidad para que coincidan con el formato deseado
        const hoursAvailable = capacityData.content
          .filter((hourData: any) => hourData.availableCapacity > 0)
          .map((hourData: any) => {
            const label = `${hourData.hourStart} - ${hourData.hourEnd}`;
            return {
              label,
              available: true, // Aquí puedes cambiar a availableCapacity si deseas, pero mantendré el formato original para mantener la coherencia
              availableCapacity: hourData.availableCapacity // Cambiar a availableCapacity
            };
          });

        // Actualizar el estado con las horas disponibles
        setHoursAvailable(hoursAvailable);

        filterHours(hoursAvailable);

        // Actualizar el estado del formulario con la fecha y si es fin de semana
        setFormData({
          ...formData,
          bookingDay: dateValue.format('YYYY-MM-DD'),
          isWeekend
        });
      }
    } catch (error) {
      console.error("Error al obtener horas disponibles:", error);
    }
  };

  console.log(hoursAvailable)


  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBox(event.target.checked)
    setFormData({
      ...formData,
      isColegial: event.target.checked
    })
    if (!event.target.checked) {
      setColegial('')
      setColegialnro('')
    }
  }
  const handleChangeFullDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBoxFullDay(event.target.checked)
    setFormData({
      ...formData,
      fullDay: event.target.checked
    })
  }

  const handleHoursChange = (event: SelectChangeEvent<string[]>) => {
    const selectedHoursArray = Array.isArray(event.target.value) ? event.target.value : []

    // Obtener la fecha actual
    const currentDate = dayjs()

    // Obtener la fecha seleccionada
    const selectedDate = valueDate ? dayjs(valueDate) : null

    // Verificar si la fecha seleccionada es la fecha actual
    const isCurrentDate = selectedDate?.isSame(currentDate, 'day')

    // const hourEarliest = 4;
    const hourEarliest = iDCompany?.body.content[0].hourEarlier

    // Obtener la hora permitida en base a hourEarlier
    const allowedHour = currentDate.hour() + hourEarliest

    // Verificar si hay alguna hora seleccionada y si la fecha seleccionada es la fecha actual
    if (selectedHoursArray.length > 0 && isCurrentDate) {
      // Verificar si alguna de las horas seleccionadas excede el límite permitido
      const exceedLimit = selectedHoursArray.some((hourRange) => {
        const hours24format = convertTo24HourFormat(hourRange);
        const [startHour] = hours24format.split(' - ')

        // Obtener la hora de inicio en formato numérico
        const startHourNum = parseInt(startHour.split(':')[0])

        // Verificar si la hora de inicio excede el límite permitido
        if (startHourNum <= allowedHour) {
          if (hourEarliest > 1) {
            setErrorMsg && setErrorMsg(`${t('onlyReserve')} ${hourEarliest} ${t('hoursBefore')}`)
          } else {
            setErrorMsg && setErrorMsg(`${t('onlyReserve')} ${hourEarliest} ${t('hourBefore')}`)
          }
          setSelectOpen(false)
          return true
        }
      })

      if (exceedLimit) {
        // Si alguna hora excede el límite permitido, mostrar alerta
        setHourValidationAlert(exceedLimit)
        setSelectedHours([])

      } else {
        // Convertir las horas seleccionadas al formato de 24 horas
        const selectedHours24Format = selectedHoursArray.map(convertTo24HourFormat)

        // Ordenar las horas seleccionadas en formato de 24 horas
        selectedHours24Format.sort()

        // Convertir las horas seleccionadas al formato de 12 horas
        const selectedHours12Format = selectedHours24Format.map(formatTo12Hour)

        // Actualizar el estado con las horas en formato de 24 horas
        setFormData({
          ...formData,
          hours: selectedHours24Format // Usar el formato de 24 horas para el formData
        })

        // Actualizar el estado con las horas en formato de 12 horas
        setSelectedHours(selectedHours12Format)

      }

    } else {
      // Si no hay horas seleccionadas o la fecha seleccionada no es la fecha actual, desactivar la selección
      setHourValidationAlert(false)

      // Convertir las horas seleccionadas al formato de 24 horas
      const selectedHours24Format = selectedHoursArray.map(convertTo24HourFormat)

      // Ordenar las horas seleccionadas en formato de 24 horas
      selectedHours24Format.sort()

      // Convertir las horas seleccionadas al formato de 12 horas
      const selectedHours12Format = selectedHours24Format.map(formatTo12Hour)

      // Actualizar el estado con las horas en formato de 24 horas
      setFormData({
        ...formData,
        hours: selectedHours24Format // Usar el formato de 24 horas para el formData
      })

      // Actualizar el estado con las horas en formato de 12 horas
      setSelectedHours(selectedHours12Format)
    }
  }

  console.log(selectedHours)

  const validateColegial = async (data: string) => {
    setErrorColegial(false)
    if (formData.isColegial /* && colegial.name === '' */) {
      console.log('Entra validar colegial condicion')
      setLoader(true)
      const getColegial = await getAsociado(data, iDCompany?.body.content[0].url)
      console.log(getColegial, 'colegial')
      if (getColegial?.status === 200) {
        if (getColegial.body.length > 0) {
          if (getColegial.body[0].status === 0) {
            setColegial(getColegial?.body[0])
            setErrorColegial('')
            //handleNext()
          } else {
            setErrorColegial(`${t('error1Associated')}`)
          }
        } else {
          setColegial('')
          setErrorColegial(`${t('error2Associated')}`)
        }
      } else {
        setErrorColegial(`${t('error3Associated')}`)
      }
      setLoader(false)
    } else {
      setErrorColegial('')
    }
  }
  const onSubmit = async () => {
    setErrorColegial(false)

    if (formData.isColegial && colegial.name === '') {
      setLoader(true)
      const getColegial = await getAsociado(formData.nroColegial, iDCompany?.body.content[0].url)
      console.log(getColegial, 'colegial')
      if (getColegial?.status === 200) {
        if (getColegial.body.length > 0) {
          if (getColegial.body[0].status === 0) {
            setColegial(getColegial?.body[0])
            //handleNext()
          } else {
            setErrorColegial(`${t('error1Associated')}`)
          }
        } else {
          setErrorColegial(`${t('error2Associated')}`)
        }
      } else {
        setErrorColegial(`${t('error3Associated')}`)
      }
      setLoader(false)
    } else {
      handleNext()
    }
  }

  useEffect(() => {
    // Verificar si areaDetail.capacity está definido y no es cero
    if (areaDetail && areaDetail.capacity !== undefined && areaDetail.capacity !== 0 && areaCapacity) {
      setFormData({ ...formData, spaces: areaDetail.capacity });
    }
  }, [areaDetail]);


  function filterHours(hoursAvailable: any) {
    console.log(hoursAvailable)
    if (!hoursAvailable) {
      return;
    } else if (!areaDetail?.hasCapacity) {
      setHoursAvailableCapacity(hoursAvailable)

    } else {
      const filteredHours = hoursAvailable.filter((hour: any) => hour.availableCapacity >= formData.spaces)

      setHoursAvailableCapacity(filteredHours);

    }
  }


  useEffect(() => {
    if (formData.spaces != 0 && formData.spaces != null) {
      filterHours(hoursAvailable)

    }


  }, [formData.spaces]);


  return (
    <>
      <Grid container spacing={4} justifyContent={'center'}>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            borderRight: {
              lg: '1px solid'
            },
            pr: 3
          }}
        >
          {isMd || isXl ? (
            <TextInformation title={`${t('scheduleTheDay')}`} />
          ) : (
            <Divider sx={{ my: 4, height: '1px' }} />
          )}
          <BoxSchedulesStyled>
            <Typography variant="h4" align="center" gutterBottom sx={{ mt: 2 }}>
              {formData.desc}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                minDate={dayjs().add(2, 'hour')}
                disablePast
                value={valueDate}
                onChange={(newValue) => {
                  setSelectedHours([])
                  setValueDate(newValue)
                  getHours(newValue)
                  setCheckBoxFullDay(false)
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </BoxSchedulesStyled>
          <Stack hidden={!formData.bookingDay} sx={{ mt: 2 }}>
            <InputContainerStiled
              sx={{ display: areaDetail?.reservationType ? 'grid' : 'none' }}
              hidden={areaDetail?.reservationType}
            >
              <Typography variant="body1">{t('allDay')}</Typography>
              <Box display={'flex'} alignItems="center">
                <Checkbox
                  disabled={disabledFullday}
                  checked={checkBoxFullDay}
                  onChange={handleChangeFullDay}
                  sx={{ ml: -1.5, '& .MuiSvgIcon-root': { fontSize: 32 } }}
                />
                {disabledFullday && (
                  <Tooltip title={'Ya exiten horas reservadas este día'}>
                    <Help color={'primary'} />
                  </Tooltip>
                )}
              </Box>
            </InputContainerStiled>
            <CustomSelectMultiple
              disabled={checkBoxFullDay}
              listItems={
                hoursAvailableCapacity
                  ? hoursAvailableCapacity.map((item: any) => ({
                    ...item,
                    label: formatTo12Hour(item.label), // Mostrar en formato de 12 horas visualmente
                    value: convertTo24HourFormat(item.value) // Utilizar formato de 24 horas internamente
                  }))
                  : []
              }
              open={selectOpen}
              onOpen={() => setSelectOpen(true)} // Función para abrir el Select
              onClose={() => setSelectOpen(false)} // Función para cerrar el Select
              value={selectedHours}
              onChange={handleHoursChange}
              label={`${t('schedules')}`}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 280,
                    opacity: 1
                  }
                }
              }}
            />
          </Stack>
          {errorColegial && (
            <Typography variant="body1" align="center" fontWeight={600} color="error" mt={2}>
              {errorColegial}
            </Typography>
          )}
          {loader && <Progress padding="0" size={40} />}

          <StepsBtn
            onClickBack={handleBack}
            //onClickNext={validateColegial}
            onClickNext={onSubmit}
            disabledNext={disabledNextBtn}
            TextButtonNext={
              formData.isColegial && colegial.name === '' ? t('consultCollegiate') : t('continue')
            }
          />
        </Grid>

        <Grid item xs={12} lg={4} sx={{ pt: '65px!important' }}>
          <Typography variant="h5" align="center" gutterBottom>
            {t('schedules')}
          </Typography>
          {areaDetail?.startTime !== areaDetail?.startTimeWeekend ||
            areaDetail?.endTime !== areaDetail?.endTimeWeekend ? (
            <>
              <Typography variant="subtitle1" align="center">
                {t('mondayToFriday')}
              </Typography>
              <Typography variant="subtitle1" align="center">
                {formatSingleHourTo12Hour(areaDetail?.startTime)} -{' '}
                {formatSingleHourTo12Hour(areaDetail?.endTime)}
              </Typography>
              <Typography variant="subtitle1" align="center">
                {t('saturdayAndSunday')}
              </Typography>
              <Typography variant="subtitle1" align="center">
                {formatSingleHourTo12Hour(areaDetail?.startTimeWeekend)} -{' '}
                {formatSingleHourTo12Hour(areaDetail?.endTimeWeekend)}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="subtitle1" align="center">
                {t('mondayAndSunday')}
              </Typography>
              <Typography variant="subtitle1" align="center">
                {areaDetail?.startTime} - {areaDetail?.endTime}
              </Typography>
            </>
          )}
          <Typography variant="h5" align="center" sx={{ pt: 4 }} gutterBottom>
            {t('price')}
          </Typography>
          {haveAssociated && (
            <Typography variant="subtitle1" align="center">
              {formatNumber(areaDetail?.associatedAmount, currency)} {t('associated')}
            </Typography>
          )}

          <Typography variant="subtitle1" align="center">
            {formatNumber(areaDetail?.publicAmount, currency)}{' '}
            {haveAssociated && t('particular')}
          </Typography>

          {((areaCapacity || (areaDetail && areaDetail.hasCapacity)) &&
            <Stack>
              <TextField
                label={t('spaces')}
                type="number"
                value={(areaDetail && areaDetail.capacity != undefined && areaDetail.capacity != 0 && areaCapacity) ? areaDetail.capacity : formData.spaces}
                onChange={(event) => {
                  const newValue = parseInt(event.target.value)
                  if (newValue >= 0 || event.target.value === '') {
                    setFormData({ ...formData, spaces: event.target.value });
                    setSelectedHours([])
                  }
                }}
                fullWidth
                margin="normal"
                required
                disabled={areaDetail && areaDetail.capacity !== undefined && areaDetail.capacity !== 0 && areaCapacity} // Deshabilita si areaDetail tiene capacidad definida y no es cero
                error={
                  formData.spaces === '' || (!areaCapacity && !(areaDetail && areaDetail.capacity !== undefined)) || formData.spaces > (areaCapacity ? areaCapacity : (areaDetail && areaDetail.capacity))
                }
                helperText={
                  (!areaCapacity && !(areaDetail && areaDetail.capacity !== undefined)) || formData.spaces > (areaCapacity ? areaCapacity : (areaDetail && areaDetail.capacity))
                    ? t('errorSpaces')
                    : formData.spaces === ''
                      ? t('requiredField')
                      : ''
                }
              />
            </Stack>
          )}
          {haveAssociated && (
            <>
              <Box sx={{ border: `solid 2px ${grey[200]}`, padding: '8px 24px 24px', mt: 4 }}>
                <InputContainerStiled sx={{ display: 'grid', gridTemplateColumns: '75% 25% ' }}>
                  <Typography variant="body1">{t('isAssociated')}</Typography>
                  <Checkbox
                    checked={checkBox}
                    onChange={handleCheckbox}
                    sx={{ ml: -1.5, '& .MuiSvgIcon-root': { fontSize: 32 } }}
                  />
                </InputContainerStiled>
                <Box hidden={!formData.isColegial}>
                  <InputContainerStiled className="inputColegiate" sx={{ display: 'grid' }}>
                    <Typography variant="body1">{t('nAssociated')}</Typography>
                    <FancyInput
                      type={'number'}
                      fullWidth
                      required={formData.isColegial}
                      value={colegialnro}
                      onChange={(event) => {
                        if (event.target.value.length === 0) {
                          setColegialnro('')
                          setFormData({ ...formData, nroColegial: '' })
                          setErrorColegial('')
                          validateColegial('')
                        } else {
                          setColegialnro(event.target.value)
                          setFormData({ ...formData, nroColegial: event.target.value })
                          validateColegial(event.target.value)
                        }
                      }}
                    />
                  </InputContainerStiled>
                </Box>
              </Box>
              {colegial.name !== '' && (
                <Typography
                  variant="h6"
                  textAlign={'center'}
                  color="primary"
                  fontWeight={'bold'}
                  sx={{ mt: 3 }}
                >
                  {colegial.name}
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {hourValidationAlert && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={t('modifyReservationTime')}
          details={errorMsg}
          callback={resetErrorMsg}
          color="primary.main"
        />
      )}
    </>
  )
}

export default Schedules
