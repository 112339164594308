import { Close, ContactPage, Home, Menu, Quiz } from '@mui/icons-material'
import {
  Box,
  Container,
  Drawer,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ContactForm from '../components/ContactForm'
import FrequentQuestions from '../components/FrequentQuestions'
import { useApp } from '../hooks/useApp'
import '../i18n'
import Logo from './Logo'

const HeaderStyled = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.common.white}`,
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  position: 'fixed',
  width: 'calc(100vw - 17px)',
  top: 0,
  left: 0,
  zIndex: 1000,
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))

const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  paddingBottom: '50px'
}

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderBottom: 'solid 1px #00000020'
}))

const Header = () => {
  const { t } = useTranslation()
  const { language, handleLanhChange, handleRestart, iDCompany, companyData, setLanguage } = useApp()

  const [openDrawer, setOpenDrawer] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModalContact, setOpenModalContact] = useState(false)
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('md'))
  const languageCompany = iDCompany && iDCompany.body.content[0].language;

  useEffect(() => {
    if (iDCompany && languageCompany) {
      setLanguage && setLanguage(languageCompany)
      handleLanhChange && handleLanhChange(languageCompany)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iDCompany]);

  return (
    <>
      <HeaderStyled>
        <Container maxWidth="lg">
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} py={1}>
            <Box
              display="flex"
              alignContent={'center'}
              justifyContent={'center'}
              alignSelf={'center'}
              alignItems={'center'}
              gap={2}
            >
              {companyData.logoheader && (
                <Logo
                  onClick={() => handleRestart && handleRestart()}
                  src={companyData.logoheader}
                  alt="logo"
                />
              )}
              {!companyData.logoheader && <Box width={150} />}
            </Box>
            <Box textAlign={'center'} sx={{ cursor: 'pointer' }}>
              <Home fontSize={'large'} color={'primary'} onClick={() => handleRestart && handleRestart()} />
              <Typography fontWeight={700} marginTop="-10px" onClick={() => handleRestart && handleRestart()}>
                INICIO
              </Typography>
            </Box>
            <>
              <Stack flexDirection={'row'}>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={() => setOpenDrawer(true)}
                  edge="start"
                  sx={{ mr: 2, ...(openDrawer && { display: 'none' }) }}
                >
                  <Menu fontSize={'large'} />
                </IconButton>
                {!isSm && (
                  <FormControl sx={{ m: 1, minWidth: 60 }}>
                    <Select
                      value={language}
                      autoWidth
                      sx={{
                        '& .MuiSelect-select': {
                          minWidth: {
                            xs: '30px!important',
                            md: '90px!important'
                          }
                        }
                      }}
                      onChange={(e: any) => handleLanhChange && handleLanhChange(e.target.value)}
                    >
                      <MenuItem value="en">{t('english')}</MenuItem>
                      <MenuItem value="es">{t('spanish')}</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Stack>
              <Drawer anchor={'right'} open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <Box sx={{ maxWidth: '100%', width: 350 }}>
                  <List>
                    <ListItemStyled sx={{ justifyContent: 'flex-end' }}>
                      <IconButton aria-label="close" onClick={() => setOpenDrawer(false)}>
                        <Close fontSize="large" color="primary" />
                      </IconButton>
                    </ListItemStyled>
                    <ListItemStyled>
                      <ListItemButton onClick={() => setOpenModalContact(true)}>
                        <ListItemIcon>
                          <ContactPage color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={'Contáctanos'} />
                      </ListItemButton>
                    </ListItemStyled>
                    <ListItemStyled>
                      <ListItemButton onClick={() => setOpenModal(true)}>
                        <ListItemIcon>
                          <Quiz color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={'Preguntas frecuentes'} />
                      </ListItemButton>
                    </ListItemStyled>
                    {isSm && (
                      <ListItemStyled sx={{ borderBottom: 0 }}>
                        <FormControl sx={{ m: 1, minWidth: 60 }}>
                          <Select
                            value={language}
                            autoWidth
                            sx={{
                              '& .MuiSelect-select': {
                                minWidth: {
                                  xs: '30px!important',
                                  md: '90px!important'
                                }
                              }
                            }}
                            onChange={(e: any) =>
                              handleLanhChange && handleLanhChange(e.target.value)
                            }
                          >
                            <MenuItem value="en">En</MenuItem>
                            <MenuItem value="es">Es</MenuItem>
                          </Select>
                        </FormControl>
                      </ListItemStyled>
                    )}
                  </List>
                </Box>
              </Drawer>
            </>
          </Stack>
        </Container>
      </HeaderStyled>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...styleModal,
            width: { xs: '90%', md: '800px' },
            height: { xs: '90%', md: 'auto' },
            overflowY: {
              xs: 'scroll',
              md: 'hidden'
            }
          }}
        >
          <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginBottom={2}>
            <Close onClick={() => setOpenModal(false)} />
          </Box>

          <FrequentQuestions />
        </Box>
      </Modal>
      <Modal
        open={openModalContact}
        onClose={() => setOpenModalContact(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: { xs: '80%', md: '600px', paddingBottom: '0px' } }}>
          <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginBottom={2}>
            <Close onClick={() => setOpenModalContact(false)} />
          </Box>
          <ContactForm />
        </Box>
      </Modal>
    </>
  )
}

export default Header
