import { useRef, useState, useEffect } from 'react'
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  useTheme
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import '../i18n'

export interface CustomSelectTypes extends SelectProps {
  label: string
  disabledEmpty?: boolean
  listItems:
    | string[]
    | {
        label: string
        disabled: boolean
      }[]
  value: string[] | string
  onChange: any
  textTransform?: string
}

export const CustomSelectMultiple = ({
  label,
  listItems,
  value,
  onChange,
  ...rest
}: CustomSelectTypes) => {
  const theme = useTheme()
  return (
    <FormControl
      sx={{
        width: '100%',
        background: theme.palette.common.white,
        borderRadius: theme.spacing(0.5)
      }}
    >
      <InputLabel id="multiple-checkbox-label">{label}</InputLabel>
      <Select
        fullWidth
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        value={value}
        onChange={onChange}
        {...rest}
        // @ts-ignore eslint-disable-next-line
        renderValue={(selected) => selected.join(', ')}
        input={<OutlinedInput label={label} />}
      >
        {listItems.map((type: any) => (
          <MenuItem key={type.label} value={type.label} disabled={!type.available}>
            <Checkbox checked={value.indexOf(type.label) > -1} />
            <ListItemText primary={type.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const CustomSelect = ({
  label,
  listItems,
  value,
  onChange,
  textTransform,
  disabledEmpty,
  ...rest
}: CustomSelectTypes) => {
  const { t } = useTranslation()
  const inputLabel = useRef<any>(null)
  const [labelWidth, setLabelWidth] = useState(0)
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel
        shrink
        ref={inputLabel}
        sx={{
          background: '#fff',
          padding: '0 3px',
          marginLeft: '-3px'
        }}
      >
        {label ?? `${t('select')}`}
      </InputLabel>
      <Select fullWidth displayEmpty value={value} onChange={onChange} {...rest}>
        <MenuItem value="" disabled={disabledEmpty}>
          {t('select')}
        </MenuItem>

        {listItems.map((type: any) => (
          <MenuItem
            key={type}
            value={type}
            sx={{ textTransform: textTransform ? textTransform : 'capitalize' }}
          >
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
