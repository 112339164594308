import { Typography, styled, Box } from '@mui/material'
import { formatNumber, formatterMoney } from '../utils/commons'
import { useTranslation } from 'react-i18next'
import '../i18n'

const BoxStyled = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  padding: ' 8px 24px',
  borderLeft: `25px solid  ${theme.palette.primary.main}`,
  borderRadius: 3,
  width: '100%',
  maxWidth: '500px',
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    '& br': {
      display: 'none'
    }
  }
}))



const TotalToPay = ({ amount, currency }: { amount: number, currency : string }) => {
  const { t } = useTranslation()

  return (
    <BoxStyled>
      <Typography variant="body1" sx={{ display: { xs: 'inline-block', sm: 'block' } }}>
        {t('totalToPay')}: <b> {formatNumber(amount, currency)}</b>
      </Typography>
    </BoxStyled>
  )
}
export default TotalToPay
