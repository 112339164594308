import i18n from 'i18next'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { getCompanySpaces, getSpaces } from '../services/Services'
import { geAreasDataTypes } from '../types/Services'
import { cartDataTypes, colegialInitType } from '../types/Views'
import { getItem, setItem } from '../utils/LocalStorageManager'
import { cartDataInit, colegialDataInit, formDataInit } from '../utils/data'
import { getCurrentURL } from '../utils/utils'

interface requestTypes {
  reserved: boolean
  setReserved?: Dispatch<SetStateAction<boolean>>
  language: string
  setLanguage?: Dispatch<SetStateAction<string>>
  handleLanhChange?: (lang: string) => void
  iDCompany: geAreasDataTypes | null
  setIDCompany?: Dispatch<SetStateAction<geAreasDataTypes | null>>
  areasData: geAreasDataTypes | undefined
  setAreasData?: Dispatch<SetStateAction<geAreasDataTypes | undefined>>
  loading: boolean
  setLoading?: Dispatch<SetStateAction<boolean>>
  successMsg: string
  setSuccessMsg?: Dispatch<SetStateAction<string>>
  resetSuccessMsg?: () => void
  resetErrorMsg?: () => void
  errorMsg: string
  setErrorMsg?: Dispatch<SetStateAction<string>>
  formData: any
  setFormData?: Dispatch<SetStateAction<any>>
  colegial: colegialInitType
  setColegial?: Dispatch<SetStateAction<colegialInitType>>
  cartData: cartDataTypes
  setCartData?: Dispatch<SetStateAction<cartDataTypes>>
  disabledFullday: boolean
  setDisabledFullday?: Dispatch<SetStateAction<boolean>>
  payOrder: any
  setPayOrder?: Dispatch<SetStateAction<any>>
  haveAssociated: boolean
  setHaveAssociated?: Dispatch<SetStateAction<boolean>>
  activeTab: number
  setActiveTab?: Dispatch<SetStateAction<number>>
  handleRestart?: () => void
  companyData?: any;
  setCompanyDaya?: Dispatch<SetStateAction<any>>
}

const initialState = {
  reserved: false,
  language: 'es',
  iDCompany: null,
  areasData: undefined,
  loading: false,
  successMsg: '',
  errorMsg: '',
  formData: formDataInit,
  colegial: colegialDataInit,
  cartData: cartDataInit,
  disabledFullday: false,
  haveAssociated: false,
  payOrder: 0,
  activeTab: 0
}

const AppContext = createContext<requestTypes>(initialState)

const AppProvider = ({ children }: any) => {
  const [reserved, setReserved] = useState(initialState.reserved)
  const [language, setLanguage] = useState(initialState.language)
  const [iDCompany, setIDCompany] = useState<geAreasDataTypes | null>(null)
  const [areasData, setAreasData] = useState<geAreasDataTypes | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const url = getCurrentURL()
  const [formData, setFormData] = useState<any>(formDataInit)
  const [colegial, setColegial] = useState<colegialInitType>(colegialDataInit)
  const [cartData, setCartData] = useState<cartDataTypes>(cartDataInit)
  const [disabledFullday, setDisabledFullday] = useState<boolean>(false)
  const [payOrder, setPayOrder] = useState<any>()
  const [haveAssociated, setHaveAssociated] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const [companyData, setCompanyData] = useState({
    email: '',
    phone: '',
    socials: {},
    logofooter: '/images/favicon.ico',
    logoheader: ''
  });

  useEffect(() => {
    if (iDCompany && iDCompany.body) {

      const company = iDCompany.body.content[0];
      const socialsData = company.listSocialNetwork;

      // Actualizar el estado companyData
      setCompanyData({
        email: company.mail,
        phone: company.phone,

        socials: socialsData.reduce((acc: any, item: any) => {
          acc[item.name] = item.url;
          return acc;
        }, {},),
        logofooter: '/images/favicon.ico',
        logoheader: company.urlLog
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iDCompany]);

  useEffect(() => {
    const updateFavicon = () => {
      const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
      if (favicon) {
        favicon.href = companyData?.logoheader || '/images/favicon.ico';
      }
    };

    updateFavicon();
  }, [companyData]);

  const resetErrorMsg = () => {
    setErrorMsg('')
  }

  const resetSuccessMsg = () => {
    setSuccessMsg('')
  }

  const handleLanhChange = async (lang: string) => {
    setLanguage(lang)
    await setItem('lang', lang)
    const localLang: any = await getItem('lang')
    i18n.changeLanguage(localLang)
  }

  const handleRestart = () => {
    setCartData(cartDataInit)
    setActiveTab(0)
    setColegial(colegialDataInit)
    setFormData(formDataInit)
    setReserved && setReserved(false)
  }

  useLayoutEffect(() => {
    setLoading(true)
    let isMounted = true
    const asyncEffect = async () => {
      if (isMounted) {
        const getIDCompany = await getCompanySpaces(url)
        const getData = await getSpaces(url)
        if (getIDCompany && getData && Object.keys(getData?.body).length > 0) {
          console.log('entra aquí')
          console.log(getIDCompany)
          // setHourEarlier()
          setIDCompany(getIDCompany)
          setAreasData(getData)
          setLoading(false)
        } else {
          console.log('entra false')
          setLoading(false)
        }
      }
    }
    asyncEffect()
    return () => {
      isMounted = false
    }
  }, [url])

  useEffect(() => {
    console.log('loading context: ', loading)
  }, [loading])

  const formContextValues: requestTypes = useMemo(
    () => ({
      reserved,
      setReserved,
      language,
      setLanguage,
      handleLanhChange,
      iDCompany,
      setIDCompany,
      resetSuccessMsg,
      resetErrorMsg,
      loading,
      setLoading,
      areasData,
      successMsg,
      setSuccessMsg,
      errorMsg,
      setErrorMsg,
      formData,
      setFormData,
      colegial,
      setColegial,
      cartData,
      setCartData,
      disabledFullday,
      setDisabledFullday,
      payOrder,
      setPayOrder,
      haveAssociated,
      setHaveAssociated,
      activeTab,
      setActiveTab,
      handleRestart,
      setCompanyData,
      companyData,
    }),
    [
      reserved,
      language,
      iDCompany,
      loading,
      areasData,
      successMsg,
      errorMsg,
      formData,
      colegial,
      disabledFullday,
      payOrder,
      haveAssociated,
      cartData,
      companyData,
      activeTab,
    ]
  )

  return <AppContext.Provider value={formContextValues}>{children}</AppContext.Provider>
}

export { AppProvider }

export default AppContext
