import localforage from 'localforage';

/**
 * Agrega un item al store
 *
 * @param key
 * @param value
 */
export const setItem = async (key: string, value: any) => {
  await localforage.setItem(key, value).catch(error => {
    throw new Error('Error en almacenamiento de información');
  });
};

/**
 * busca un item al store
 *
 * @param key
 */
export const getItem = async (key: any) => {
  return await localforage.getItem(key).catch(error => {
    throw new Error('Error en recuperacion de información');
  });
};

/**
 * Elimina un item al store
 * @param key
 */
export const removeItem = async (key: any) => {
  await localforage.removeItem(key).catch(error => {
    throw new Error('Error en eliminar la información');
  });
};
