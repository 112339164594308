import { TipsAndUpdates } from '@mui/icons-material'
import { Box, Typography, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import '../i18n'
import { useApp } from '../hooks/useApp'

const TableLayout = ({ children,countryCode }: { children: any,countryCode?:any }) => {
  const { t } = useTranslation()
  const { iDCompany } = useApp()

  return (
    <>
      <Typography variant="h6" textAlign={'center'} color="primary.main" fontWeight={'bold'} mb={2}>
        {t('depositReception')}
      </Typography>
      <Grid container spacing={3} mb={2}>
        <Grid item>
          <Typography color="primary.main" fontWeight={'bold'}>
            {t('toName')}:
          </Typography>
          <Typography color="primary.main" fontWeight={'bold'}>
            {t('reportToEmail')}:
          </Typography>
          <Typography color="primary.main" fontWeight={'bold'}>
            {t('phone')}:
          </Typography>
          {/* <Typography color="primary.main" fontWeight={'bold'}>
            Cédula juridica:
          </Typography> */}
        </Grid>
        <Grid item>
          <Typography>{iDCompany && iDCompany.body.content[0].description}</Typography>
          <Typography>{iDCompany && iDCompany.body.content[0].mail}</Typography>
          <Typography>{"("}{countryCode}{")"}{" "}{iDCompany && iDCompany.body.content[0].phone}</Typography>
        </Grid>
      </Grid>

      {children}
      <Box mt={4} textAlign={'center'}>
        <Typography variant="body2">{t('sendProofFollowingMedia')}: <TipsAndUpdates /></Typography>
        <Box sx={{ py: 0 }}>
          <Typography color="primary.main" fontWeight={'bold'} variant="body2" component={'span'}>
            {t('email')}:{' '} 
          </Typography>
          <Typography variant="body2" component={'span'}>
            {iDCompany && iDCompany.body.content[0].mail} 
          </Typography>
        </Box>
        <Box sx={{ py: 0 }}>
          {/* <Typography color="primary.main" fontWeight={'bold'} variant="body2" component={'span'}>
            WhatsApp:{' '}
          </Typography>
          <Typography variant="body2" component={'span'}>
          9999-9999
          </Typography> */}

          {/* <Typography variant="body2">
            {t('rememberToDetail')} <TipsAndUpdates />
          </Typography> */}
        </Box>
      </Box>
    </>
  )
}
export default TableLayout
