import { Divider, Typography } from '@mui/material'

const TextInformation = ({ title }: { title: string }) => {
  return (
    <>
      <Typography variant="h5" align="center" mb={2} pt={4}>
        {title}
      </Typography>
      <Divider />
    </>
  )
}
export default TextInformation
