import { useEffect, useState } from 'react'
import {
  SelectChangeEvent,
  Box,
  styled,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  tableCellClasses,
  ImageListItem,
  Grid,
  useMediaQuery,
  useTheme,
  Typography
} from '@mui/material'
import TextInformation from '../../components/TextInformation'

import { CustomSelect } from '../../components/CustomSelect'
import FancyInput from '../../components/FancyInput'
import StepsBtn from '../../components/StepsBtn'
import TotalToPay from '../../components/TotalToPay'
import { formatCreditCardNumber, formatExpirationDate } from '../../utils/formatCreditCardNumber'
import { bankRows, listPayments, sinppeMovilRows } from '../../utils/data'
import TableLayout from '../../layouts/TableLayout'
import { getPayOrder, getPayMethods, getCountries } from '../../services/Services'
import shortid from 'shortid'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/navigation'
import 'swiper/css'
import { Navigation, Mousewheel } from 'swiper'
import Lightbox from 'yet-another-react-lightbox'
import { useTranslation } from 'react-i18next'
import '../../i18n'
import { useApp } from '../../hooks/useApp'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))
const BoxStyled = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: ' 1fr 1fr',
  alignItems: 'center',
  width: '100%',
  marginTop: 10,
  rowGap: theme.spacing(2),
  columnGap: theme.spacing(4),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr'
  }
}))
const BoxSelecStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: ' center',
  marginBottom: '20px'
}))

const ImageListItemStyled = styled(ImageListItem)(({ theme }) => ({
  overflow: 'hidden',
  cursor: 'pointer',
  '& img': {
    transition: 'all 0.5s ease',
    '&:hover': { transform: 'scale(1.07)' }
  },
  img: {
    objectFit: 'cover',
    objectPosition: 'center'
  }
}))

const SwiperSlideItem = styled(SwiperSlide)(({ theme }) => ({
  height: 'auto!important'
}))
const LightboxStyled = styled(Lightbox)(({ theme }) => ({
  '& .yarl__container': {
    backgroundColor: '#000000b0'
  },
  '& img': {
    //border: '3px solid white',
    width: '600px !important',
    height: '400px !important',
    maxWidth: '600px !important',
    maxHeight: '400px !important'
  }
}))

const Payments = ({
  handleBack,
  handleNext,
  formData,
  setFormData,
  cartData,
  colegial,
  setPayOrder,
  currency,
}: any) => {
  const { t } = useTranslation()
  const { setReserved, iDCompany, setErrorMsg } = useApp()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down('md'))
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))

  const [cardNumberInput, setCardNumberInput] = useState(
    formData.cardNumber ? formData.cardNumber : ''
  )
  const [cardUserNameInput, setCardUserNameInput] = useState(
    formData.cardUserName ? formData.cardUserName : ''
  )
  const [cardExpirationDateInput, setCardExpirationDateInput] = useState(
    formData.cardExpirationDate ? formData.cardExpirationDate : ''
  )
  const [cardCVVInput, setCardCVVInput] = useState(formData.cardCVV ? formData.cardCVV : '')
  const [select, setSelect] = useState(formData.payMethoLabel ? formData.payMethoLabel : '')
  const [formatCvv, setFormatCvv] = useState<boolean>(true)
  const [cvvError, setCVVError] = useState<string | null>(null);
  const [cardType, setCardType] = useState<String>("")
  const [formatCard, setFormatCard] = useState<boolean>(true)
  const [expirationDateError, setExpirationDateError] = useState<string | null>(null);
  const [companyPayMethods, setCompanyPayMethods] = useState<any>([]);
  const [filteredPayments, setFilteredPayments] = useState<any>([])
  const [countryData, setCountryData] = useState<any>([])
  const countryCompanyId = iDCompany && iDCompany.body.content[0].country;



  const [urlImg, setUrlImg] = useState<string[] | undefined>(
    formData.photoGallery ? formData.photoGallery : undefined
  )
  const [open, setOpenLightbox] = useState<boolean>(false)
  const [indexImg, setIndexImg] = useState<number>(0)

  useEffect(() => {
    document.documentElement.scrollTop = 0
  }, [])

  useEffect(() => {
    const handleTest = async () => {
      const urlPhotos = await getPayMethods(iDCompany && iDCompany.body.content[0].id)
      if (urlPhotos) {
        setCompanyPayMethods(urlPhotos.body)
        filterPaymentMethods(urlPhotos.body)
      }
      console.log('Test', urlPhotos)
    }
    handleTest()
  }, [])

  
  useEffect(() => {
    const fetchCountry = async () => {
      let data = await getCountries(0);
      if (data && Array.isArray(data.body.content)) {
        const country = data.body.content.filter((item:any) => item.id === countryCompanyId);
        setCountryData(country);
      }
    };
    fetchCountry();
  }, []);

  const filterPaymentMethods = (methods: any) => {
    const { account, card } = methods
    let paymentOptions = []

    if (card && card.length > 0) {
      paymentOptions.push('Tarjeta')
    }

    if (account && account.length > 0) {
      const hasType3 = account.some((acc: any) => acc.type === 3)
      const hasType4 = account.some((acc: any) => acc.type === 4)

      if (hasType3) {
        paymentOptions.push('Depósito')
      }
      if (hasType4) {
        paymentOptions.push('Sinpe Móvil')
      }
    }

    setFilteredPayments(paymentOptions)
    if (!select && paymentOptions.length > 0) {
      setFormData({
        ...formData,
        payMethod: paymentOptions[0]
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(' ', '')
          .toLocaleUpperCase(),
        payMethoLabel: paymentOptions[0]
      })
      setSelect(paymentOptions[0]);
    }
  }

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string
    setFormData({
      ...formData,
      payMethod: value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(' ', '')
        .toLocaleUpperCase(),
      payMethoLabel: value
    })
    setSelect(value)
  }

  const handleInputCardNumber = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = event.target.value;

    // Actualizar el estado con el nuevo valor
    const formattedPhoneNumber = formatCreditCardNumber(inputValue, setCardType);
    setCardNumberInput(formattedPhoneNumber);
    setFormData({ ...formData, cardNumber: inputValue });

    // Realizar la validación de longitud mínima
    if ((cardType === "dinersclub" && inputValue.replace(/\s/g, '').length >= 14) || (cardType === "amex" && inputValue.replace(/\s/g, '').length >= 15) || (cardType === "default" && inputValue.replace(/\s/g, '').length >= 16)) {
      const formattedPhoneNumber = formatCreditCardNumber(inputValue, setCardType);
      setCardNumberInput(formattedPhoneNumber);
      setFormatCard(true);
    } else {
      setFormatCard(false);
    }
  };

  // const handleInputCardExpirationDate = (
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   console.log(Number(event.target.value.slice(0, 2)))
  //   if (Number(event.target.value.slice(0, 2)) < 13) {
  //     const formattedExpirationDate = formatExpirationDate(event.target.value)
  //     console.log(formattedExpirationDate)
  //     setCardExpirationDateInput(formattedExpirationDate)
  //     setFormData({ ...formData, cardExpirationDate: event.target.value })
  //     setFormatCvv(true)
  //   } else {
  //     setFormatCvv(false)
  //   }
  // }

  const handleInputCardExpirationDate = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = formatExpirationDate(event.target.value);

    // Verificar si el valor ingresado es una fecha válida en formato MM/YY o está vacío
    const isValidFormat = /^\d{0,2}\/\d{0,2}$/.test(inputValue);

    // Si el formato es válido o el campo está vacío, actualizar el estado
    if (isValidFormat || inputValue === '') {
      setCardExpirationDateInput(inputValue);
      setFormData({ ...formData, cardExpirationDate: inputValue });

      // Si el campo está vacío, limpiar el mensaje de error
      if (inputValue === '') {
        setExpirationDateError(null);
      } else {
        // Obtener la fecha actual
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear() % 100; // Obtener solo los últimos dos dígitos del año
        const currentMonth = currentDate.getMonth() + 1; // Sumamos 1 porque los meses van de 0 a 11

        // Dividir la fecha de vencimiento en mes y año
        const [inputMonth, inputYear] = inputValue.split('/').map(Number);

        // Validar si la fecha de vencimiento es menor que la actual
        if (inputYear < currentYear || (inputYear === currentYear && inputMonth < currentMonth)) {
          // La fecha de vencimiento es menor que la fecha actual, mostrar mensaje de error
          setExpirationDateError(t('dateFormat'));
        } else {
          // La fecha de vencimiento es válida, limpiar el mensaje de error
          setExpirationDateError(null);
        }
      }
    } else {
      // Si el formato no es válido y el campo no está vacío, no actualizamos el estado
      // Esto evitará que se eliminen caracteres inválidos
      setCardExpirationDateInput(inputValue); // Esta línea permite borrar caracteres inválidos
    }
  };




  const nextBtn = async () => {
    handleNext()
    // const getPayOrderData = {
    //   amount: cartData.allAmount,
    //   currency: 'CRC',
    //   description: 'Pago Reservas',
    //   orderReference: shortid.generate(),
    //   callback: 'https://yourhost.url/callback',
    //   additional: {
    //     customer: {
    //       name: formData.isColegial
    //         ? colegial.name
    //         : `${formData.userName} ${formData.userLastName}`,
    //       email: formData.isColegial ? colegial.email : formData.userEmail,
    //       identification: formData.isColegial ? formData.nroColegial : formData.userNroId,
    //       billingAddress: {
    //         country: 'CR',
    //         city: 'Moravia',
    //         province: 'San Jose',
    //         street1: 'Moravia, Residencial Los Colegios',
    //         street2: 'de Taco Bell 300 metros oeste, 100 norte y 300 oeste.',
    //         zip: '281-1000'
    //       },
    //       shippingAddress: {
    //         country: 'CR',
    //         city: 'Moravia',
    //         province: 'San Jose',
    //         street1: 'Moravia, Residencial Los Colegios',
    //         street2: 'de Taco Bell 300 metros oeste, 100 norte y 300 oeste.',
    //         zip: '281-1000'
    //       }
    //     },
    //     products: [
    //       {
    //         description: 'Reserva Ciagro',
    //         skuId: 'Identificador único en el comercio',
    //         quantity: 1,
    //         price: cartData.allAmount,
    //         type: 'Reserva'
    //       }
    //     ],
    //     details: {
    //       description: 'description',
    //       seller: 'seller',
    //       other: 'other details'
    //     }
    //   }
    // }
    // const apiRes = await getPayOrder(getPayOrderData)
    // setPayOrder(apiRes)
  }

  const handleLightbox = (index: any) => {
    setOpenLightbox(true)
    setIndexImg(index)
  }

  const urlImgs = urlImg?.map((img) => {
    return {
      src: img
    }
  })

  const getMessageByCardType = (cardType: any): string => {
    switch (cardType) {
      case 'amex':
        return t('amex');
      case 'dinersclub':
        return t('dinersclub');
      default:
        return t('default');
    }
  };

  console.log(filteredPayments.length)


  return (
    <>
      <Grid container spacing={4} sx={{ height: '100%', marginTop: 0 }}>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            borderRight: {
              lg: '1px solid'
            },
            pr: 3,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {(isMd || isXl) && <TextInformation title={`${t('paymentMethod')}`} />}
          <Stack>
            <Box py={3}>
              <BoxSelecStyled>
                {/*  <Typography fontWeight={'bold'}>{t('selectMethod')}</Typography> */}
                <CustomSelect
                  listItems={filteredPayments ? filteredPayments : []}
                  value={select}
                  onChange={(e: SelectChangeEvent) => handleChange(e)}
                  disabledEmpty
                  //sx={{ flexBasis: '50%' }}
                  label={t('selectMethod')}
                />
              </BoxSelecStyled>
              {filteredPayments && filteredPayments.length > 0 ? (
                <>
                  <Box hidden={select !== "Tarjeta"}>
                    <BoxStyled>
                      <FancyInput
                        required
                        autoComplete="nope"
                        type={'password'}
                        value={cardNumberInput}
                        onChange={(event) => handleInputCardNumber(event)}
                        inputProps={{
                          maxLength: 19
                        }}
                        label={t('card')}
                        validation={[
                          {
                            validate: () => formatCard,
                            msg: getMessageByCardType(cardType)
                          }
                        ]}
                      />

                      <FancyInput
                        required
                        value={cardUserNameInput}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          // Remove numbers from the input
                          const filteredValue = inputValue.replace(/[0-9]/g, '');
                          setCardUserNameInput(filteredValue);
                          setFormData({ ...formData, cardUserName: filteredValue });
                        }}
                        label={t('cardholderName')}
                      />

                      <FancyInput
                        required
                        value={cardExpirationDateInput}
                        onChange={(event) => handleInputCardExpirationDate(event)}
                        label={t('dueDate')}
                        validation={[
                          {
                            validate: () => formatCvv,
                            msg: `${t('invalidFormat')}`
                          }
                        ]}
                        error={!!expirationDateError}
                        helperText={expirationDateError}
                      />

                      <FancyInput
                        type={'password'}
                        autoComplete="nope"
                        onlyNumber
                        inputProps={{
                          maxLength: 3
                        }}
                        label={t('cvv')}
                        value={cardCVVInput}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (value.length <= 3) {
                            setCardCVVInput(value);
                            setFormData({ ...formData, cardCVV: value });
                            if (value.length < 3) {
                              setCVVError(t('cvvFormat'));
                            } else {
                              setCVVError(null);
                            }
                          }
                        }}
                        required
                        error={!!cvvError}
                        helperText={cvvError}
                      />
                    </BoxStyled>
                  </Box>
                  <Box hidden={select === "Tarjeta"}>
                    {select === "Sinpe Móvil" ? (
                      <TableLayout countryCode ={countryData && countryData[0]?.areaCode}>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>{t('half')}</StyledTableCell>
                                <StyledTableCell>{t('account')}</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {companyPayMethods && companyPayMethods.account && companyPayMethods.account
                                .filter((row: any) => row.type === 4)
                                .map((row: any) => (
                                  <TableRow key={row.name}>
                                    <TableCell width={200}>{row.name}</TableCell>
                                    <TableCell>{row.account}</TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableLayout>
                    ) : (
                      <TableLayout countryCode ={countryData && countryData[0]?.areaCode}>
                        <TableContainer component={Paper}>
                          <Table aria-label="Banck table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>{t('bank')}</StyledTableCell>
                                <StyledTableCell>{t('account')}</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {companyPayMethods && companyPayMethods.account && companyPayMethods.account
                                .filter((row: any) => row.type === 3)
                                .map((row: any) => (
                                  <TableRow key={row.id}>
                                    <TableCell width={200}>{row.name}</TableCell>
                                    <TableCell width={50}>{row.account}</TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableLayout>
                    )}
                  </Box>
                </>

              ) : (
                <Box>
                  <Typography variant="h6">
                    {<TextInformation title={`${t('paymentMethodsNotAvailable')}`} />}
                  </Typography>
                </Box>
              )}

            </Box>
          </Stack>
          <TotalToPay
            amount={colegial.BookingAdmin ? 0 : cartData.allAmount}
            currency={currency}
          />
          <StepsBtn
            onClickBack={handleBack}
            onClickNext={nextBtn}
            disabledNext={
              select === "Tarjeta" &&
              (!formData.cardNumber ||
                !formData.cardUserName ||
                !formData.cardExpirationDate ||
                !formData.cardCVV ||
                !!cvvError || !formatCard || !!expirationDateError) || filteredPayments && filteredPayments.length <= 0
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          sx={{
            overflow: 'hidden',
            pt: '20px!important',
            pb: '15px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Stack
            sx={{
              maxHeight: {
                lg: '52vh'
              }
            }}
          >
            {formData.area &&
              (urlImg !== undefined && urlImg?.length > 2 ? (
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  navigation
                  mousewheel={true}
                  direction={'horizontal'}
                  modules={[Navigation, Mousewheel]}
                  breakpoints={{
                    425: {
                      slidesPerView: 2,
                      direction: 'horizontal'
                    },
                    600: {
                      slidesPerView: 3,
                      direction: 'horizontal'
                    },
                    1201: {
                      slidesPerView: 3,
                      direction: 'vertical'
                    }
                  }}
                >
                  {urlImg &&
                    urlImg?.map((img, i) => (
                      <SwiperSlide key={i}>
                        <img alt="name" src={img} onClick={() => handleLightbox(i)} />
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : (
                urlImg &&
                urlImg?.map((img, i) => (
                  <div key={i}>
                    <img
                      alt="name"
                      height="175px"
                      src={img}
                      onClick={() => handleLightbox(i)}
                      className="img-without-slider"
                    />
                  </div>
                ))
              ))}
          </Stack>
        </Grid>
      </Grid>
      <LightboxStyled
        open={open}
        index={indexImg}
        close={() => setOpenLightbox(false)}
        slides={urlImgs}
        carousel={{ finite: true }}
        controller={{ closeOnBackdropClick: true }}
      />
    </>
  )
}

export default Payments
