import { Button, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import '../i18n'

const StepsBtn = ({
  onClickBack,
  disabledBack,
  onClickNext,
  disabledNext,
  TextButtonNext
}: any) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Stack
      direction={isXs ? 'column-reverse' : 'row'}
      justifyContent={'center'}
      alignItems={'center'}
      spacing={2}
      pb={5}
      pt={4}
    >
      <Button variant="contained" onClick={onClickBack} disabled={disabledBack}>
        {t('back')}
      </Button>
      <Button variant="contained" onClick={onClickNext} disabled={disabledNext}>
        {TextButtonNext ?? t('continue')}
      </Button>
    </Stack>
  )
}

export default StepsBtn
