import { tablesPaymentsTypes } from '../types/Views'

//TODO Agregar Método de tarjeta al final del array para habilitar esa forma de pago
 export const listPayments = ['Depósito', 'Sinpe Móvil', 'Tarjeta']
//export const listPayments = ['Tarjeta',]

export const sinppeMovilRows: tablesPaymentsTypes[] = [
  { name: 'Sinpe Móvil', account: '8832-4292' }
]
export const bankRows: tablesPaymentsTypes[] = [
  {
    name: 'BANCO DE COSTA RICA',
    currency: 'Colón',
    account: 'CR68015201001020090215',
    accountIBan: 'CR6801520100102'
  },
  {
    name: 'BANCO NACIONAL DE C.R.',
    currency: 'Colón',
    account: 'CR78015100010012129623',
    accountIBan: 'CR7801510001001'
  },
  {
    name: 'Bac San José',
    currency: 'Colón',
    account: 'CR96010200009125149241',
    accountIBan: 'CR9601020000912514'
  }
]

export const formDataInit = {
  area: '',
  desc: '',
  userName: '',
  userLastName: '',
  payMethod: 'TARJETA',
  isColegial: false,
  bookingDay: '',
  hours: [],
  BookingAdmin: false,
  nroColegial: '',
  photoGallery: '',
  detailsArea: '',
  areaType: 0,
  userNroId: ''
}

export const cartDataInit = {
  allAmount: 0,
  allBookings: []
}

export const colegialDataInit = {
  name: '',
  phone: '',
  email: '',
  BookingAdmin: false
}

