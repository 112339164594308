export const formatterMoney = (currency: string) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: currency
  });
}

export const formatNumber = (val: any,currency:any) => {
  const value = parseFloat(val);
  const formattedValue = value.toFixed(2); 

  return `${formattedValue} ${currency} `;
}
export const truncateTarjeta = (str: string): string => {
  return `${str.substring(0, 4)} **** **** ${str.substring(str.length - 4, str.length)}`
}
