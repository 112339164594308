import { Box, Grid, Stack, styled, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Lightbox from 'yet-another-react-lightbox'
import FancyInput from '../../components/FancyInput'
import StepsBtn from '../../components/StepsBtn'
import TextInformation from '../../components/TextInformation'
import TotalToPay from '../../components/TotalToPay'
import '../../i18n'
import { getCountries } from '../../services/Services'
import CountrySelector from '../../utils/countrySelector/CountrySelector'
import { formatPhoneNumber, isValidEmail } from '../../utils/inputsFormat'

const LightboxStyled = styled(Lightbox)(() => ({
  '& .yarl__container': {
    backgroundColor: '#000000b0'
  },
  '& img': {
    width: '600px !important',
    height: '400px !important',
    maxWidth: '600px !important',
    maxHeight: '400px !important'
  }
}))

interface CountryOption {
  id: number
  name: string
  areaCode: string
  flag: string | null
  userCreated: string
  userModified: string
}

const BoxStyled = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  maxWidth: '700px',
  width: '100%',
  marginTop: 30,
  rowGap: theme.spacing(4),
  columnGap: theme.spacing(4),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr'
  }
}))

const UserData = ({
  handleBack,
  handleNext,
  formData,
  setFormData,
  colegial,
  cartData,
  setActiveStep,
  setHiddenCart,
  setCartData,
  currency,
}: any) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down('md'))
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  const [phoneInput, setPhoneInput] = useState(formData.userPhone ? formData.userPhone : '')
  const [emailInput, setEmailInput] = useState(formData.userEmail ? formData.userEmail : '')
  const [areaCodeInput, setAreaCodeInput] = useState<CountryOption | null>(null)
  const [countryData, setCountryData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)

  const [urlImg, setUrlImg] = useState<string[] | undefined>(
    formData.photoGallery ? formData.photoGallery : undefined
  )
  const [open, setOpenLightbox] = useState<boolean>(false)
  const [indexImg, setIndexImg] = useState<number>(0)

  const handleFetchData = async () => {
    setLoading && setLoading(true)
    setCountryData([])
    try {
      let data = await getCountries(0)
      if (data) {
        setCountryData(data.body)
      }
      setLoading && setLoading(false)
    } catch (error: any) {
      setLoading && setLoading(false)
      // setErrorMsg && setErrorMsg(error.message);
      console.error(error)
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [])

  const disabledNextBtn = !formData.isColegial
    ? !formData.userName ||
    !formData.userLastName ||
    !isValidEmail(formData.userEmail) ||
    !phoneInput ||
    formData.userNroId.length > 15 ||
    formData.userNroId.length === 0 ||
    phoneInput.length > 15 ||
    phoneInput.length === 0 ||
    !areaCodeInput
    : false
  const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value)
    setPhoneInput(formattedPhoneNumber)
    setFormData({ ...formData, userPhone: event.target.value })
  }

  useEffect(() => {
    document.documentElement.scrollTop = 0
  }, [])

  const backBtn = () => {
    setActiveStep(0)
    setHiddenCart(false)
  }

  const backFromUser = () => {
    const oldData = cartData.allBookings
    oldData.splice(-cartData.lastItems)
    setCartData({
      allAmount: cartData.allAmount - cartData.lastAmount,
      allBookings: oldData
    })
    handleBack()
  }

  const handleLightbox = (index: any) => {
    setOpenLightbox(true)
    setIndexImg(index)
  }

  const urlImgs = urlImg?.map((img) => {
    return {
      src: img
    }
  })

  return (
    <>
      <Grid container columnSpacing={4} sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            borderRight: {
              lg: '1px solid'
            },
            pr: 3,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Stack>
            {(isMd || isXl) && <TextInformation title={`${t('userInformation')}`} />}
            {!formData.isColegial ? (
              <BoxStyled>
                <FancyInput
                  required={true}
                  value={formData.userName}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const filteredValue = inputValue.replace(/[^A-Za-z\s]/g, ''); // Permitir solo letras y espacios
                    setFormData({ ...formData, userName: filteredValue });
                  }}
                  label={t('firstName')}
                />
                <FancyInput
                  required={true}
                  value={formData.userLastName}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const filteredValue = inputValue.replace(/[^A-Za-z\s]/g, ''); // Permitir solo letras y espacios
                    setFormData({ ...formData, userLastName: filteredValue });
                  }}
                  label={t('lastName')}
                />
                <FancyInput
                  required={true}
                  value={formData.userNroId}
                  type={'text'}
                  label={t('identificationNumber')}
                  inputProps={{
                    maxLength: 15,
                    pattern: '[0-9]*' // Permitir solo números
                  }}
                  validation={[
                    {
                      validate: () => /^[0-9]*$/.test(formData.userNroId),
                      msg: 'Solo se permiten caracteres numéricos'
                    }
                  ]}
                  onChange={(event) => {
                    const value = event.target.value;
                    const filteredValue = value.replace(/[^0-9]/g, ''); // Permitir solo números
                    setFormData({ ...formData, userNroId: filteredValue });
                  }}
                />
                <FancyInput
                  value={emailInput}
                  label={t('email')}
                  onChange={(event) => {
                    setEmailInput(event.target.value)
                    setFormData({ ...formData, userEmail: event.target.value })
                  }}
                  required={true}
                  validation={[
                    {
                      validate: () => isValidEmail(formData.userEmail),
                      msg: 'Correo invalido'
                    }
                  ]}
                />
                <Box display="flex" width="100%">
                  <CountrySelector
                    value={formData ? formData.area : null}
                    disabled={false}
                    selectedCountry={areaCodeInput}
                    formData={formData}
                    setFormData={setFormData}
                    setSelectedCountry={setAreaCodeInput}
                    options={countryData}
                  />
                </Box>
                <FancyInput
                  required={true}
                  value={phoneInput}
                  onChange={handlePhoneInput}
                  label={t('phone')}
                  inputProps={{
                    maxLength: 16
                  }}
                  validation={[
                    {
                      validate: () => phoneInput.length < 15,
                      msg: `${t('noMoreFifteenChar')}`
                    }
                  ]}
                />
              </BoxStyled>
            ) : (
              <BoxStyled>
                <FancyInput disabled value={colegial.name} label={t('name')} onChange={undefined} />
                <FancyInput
                  disabled
                  value={colegial.phone}
                  label={t('phone')}
                  onChange={undefined}
                />
                <FancyInput
                  disabled
                  value={colegial.email}
                  label={t('email')}
                  onChange={undefined}
                />
                <Box display="flex" width="100%">
                  <CountrySelector
                    value={colegial.countries ? colegial?.countries.areaCode : null}
                    disabled={true}
                    selectedCountry={areaCodeInput}
                    formData={formData}
                    setFormData={setFormData}
                    setSelectedCountry={setAreaCodeInput}
                    options={countryData}
                  />
                </Box>
              </BoxStyled>
            )}
            <BoxStyled sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FancyInput
                multiline
                rows={1}
                label={t('generalObservations')}
                value={formData.generalObservations}
                onChange={(event) => setFormData({ ...formData, generalObservations: event.target.value })}
                sx={{ width: '80%', flexGrow: 1 }}
              />
            </BoxStyled>
            <TotalToPay
              amount={colegial.BookingAdmin ? 0 : cartData.allAmount}
              currency={currency}
            />
          </Stack>
          <StepsBtn
            onClickBack={formData.area === '' ? backBtn : backFromUser}
            onClickNext={handleNext}
            disabledNext={disabledNextBtn}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          sx={{
            overflow: 'hidden',
            pt: '20px!important',
            pb: '15px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Stack
            sx={{
              maxHeight: {
                lg: '52vh'
              }
            }}
          >
            {formData.area &&
              (urlImg !== undefined && urlImg?.length > 2 ? (
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  navigation
                  mousewheel={true}
                  direction={'horizontal'}
                  modules={[Navigation, Mousewheel]}
                  breakpoints={{
                    425: {
                      slidesPerView: 2,
                      direction: 'horizontal'
                    },
                    600: {
                      slidesPerView: 3,
                      direction: 'horizontal'
                    },
                    1201: {
                      slidesPerView: 3,
                      direction: 'vertical'
                    }
                  }}
                >
                  {urlImg &&
                    urlImg?.map((img, i) => (
                      <SwiperSlide key={i}>
                        <img alt="name" src={img} onClick={() => handleLightbox(i)} />
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : (
                urlImg &&
                urlImg?.map((img, i) => (
                  <div key={i}>
                    <img
                      alt="name"
                      height="175px"
                      src={img}
                      onClick={() => handleLightbox(i)}
                      className="img-without-slider"
                    />
                  </div>
                ))
              ))}
          </Stack>
        </Grid>
        <LightboxStyled
          open={open}
          index={indexImg}
          close={() => setOpenLightbox(false)}
          slides={urlImgs}
          carousel={{ finite: true }}
          controller={{ closeOnBackdropClick: true }}
        />
      </Grid>
    </>
  )
}

export default UserData
