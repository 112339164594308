export const SchedulesHours = [
  { label: '00:00 - 1:00', available: true },
  { label: '01:00 - 2:00', available: true },
  { label: '02:00 - 3:00', available: true },
  { label: '03:00 - 4:00', available: true },
  { label: '04:00 - 5:00', available: true },
  { label: '05:00 - 6:00', available: true },
  { label: '06:00 - 7:00', available: true },
  { label: '07:00 - 8:00', available: true },
  { label: '08:00 - 9:00', available: true },
  { label: '09:00 - 10:00', available: true },
  { label: '10:00 - 11:00', available: true },
  { label: '11:00 - 12:00', available: true },
  { label: '12:00 - 13:00', available: true },
  { label: '13:00 - 14:00', available: true },
  { label: '14:00 - 15:00', available: true },
  { label: '15:00 - 16:00', available: true },
  { label: '16:00 - 17:00', available: true },
  { label: '17:00 - 18:00', available: true },
  { label: '18:00 - 19:00', available: true },
  { label: '19:00 - 20:00', available: true },
  { label: '20:00 - 21:00', available: true },
  { label: '21:00 - 22:00', available: true },
  { label: '22:00 - 23:00', available: true },
  { label: '23:00 - 00:00', available: true }
]

export const filterHours = (open: any, close: any, currentDate = new Date(), bookingTime: any) => {
  const openNumber = Number(open.slice(0, 2));
  const closeNumber = Number(close.slice(0, 2));
  const arrayHoursNew: any = [];
  const now = new Date();
  const currentHour = now.getHours();

  
  const isCurrentDateToday = () => {
    return currentDate.getDate() === now.getDate() &&
           currentDate.getMonth() === now.getMonth() &&
           currentDate.getFullYear() === now.getFullYear();
  };

  
  const startHour = isCurrentDateToday() ? Math.max(openNumber, currentHour + 2) : openNumber;

  
  for (let i = startHour; i < closeNumber; i++) {
    
    if (i + bookingTime <= closeNumber) {
      const hourRangeStart = i;
      const hourRangeEnd = i + bookingTime;

      
      const hourRangeLabel = `${String(hourRangeStart).padStart(2, '0')}:00 - ${String(hourRangeEnd).padStart(2, '0')}:00`;

      
      arrayHoursNew.push({
        label: hourRangeLabel,
        available: true 
      });

      
      i += bookingTime - 1; 
    }
  }

  return arrayHoursNew;
};

export const getHoursToBooking = (selethoras: any) => {
  console.log(selethoras)
  const resumenHours: any = []
  selethoras.forEach((hour: any) => {
    const startHour = hour.slice(0, 5); // Extraemos la hora de inicio
    const endHour = hour.slice(8, 13); // Extraemos la hora de fin
    const totalHours = Number(endHour.slice(0, 2)) - Number(startHour.slice(0, 2)); // Calculamos la duración total
    resumenHours.push({
      startDate: startHour,
      endDate: endHour,
      totalHours: totalHours
    });
  });
  return resumenHours;
}

export const sortHours = (selethoras: any) => {
  console.log(selethoras);
  
  selethoras.sort(function (a: any, b: any) {
    // Extraer las horas de inicio de a y b y convertirlas a números
    const hourA = Number(a.slice(0, 2));
    const hourB = Number(b.slice(0, 2));
    
    // Comparar las horas numéricamente
    return hourA - hourB;
  });

  console.log(selethoras);
  
  return selethoras;
}

export const formatTo12Hour = (time: string) => {
  const [start, end] = time.split(' - ');
  const [startHour, startMinutes] = start.split(':');
  const [endHour, endMinutes] = end.split(':');

  let formattedStartHour = parseInt(startHour, 10);
  let formattedEndHour = parseInt(endHour, 10);
  let startPeriod = 'AM';
  let endPeriod = 'AM';

  if (formattedStartHour === 0) {
    formattedStartHour = 12;
  } else if (formattedStartHour === 12) {
    startPeriod = 'PM';
  } else if (formattedStartHour > 12) {
    formattedStartHour -= 12;
    startPeriod = 'PM';
  }

  if (formattedEndHour === 0) {
    formattedEndHour = 12;
  } else if (formattedEndHour === 12) {
    endPeriod = 'PM';
  } else if (formattedEndHour > 12) {
    formattedEndHour -= 12;
    endPeriod = 'PM';
  }

  return `${formattedStartHour}:${startMinutes} ${startPeriod} - ${formattedEndHour}:${endMinutes} ${endPeriod}`;
}


export const convertTo24HourFormat = (time: string) => {
  if (!time) {
    return '';
  }

  const [start, end] = time.split(' - ');
  if (!start || !end) {
    return '';
  }

  const [startHour, startMinutes, startPeriod] = start.split(/:| /);
  const [endHour, endMinutes, endPeriod] = end.split(/:| /);

  let formattedStartHour = parseInt(startHour, 10);
  let formattedEndHour = parseInt(endHour, 10);

  if (startPeriod === 'PM' && formattedStartHour !== 12) {
    formattedStartHour += 12;
  }

  if (endPeriod === 'PM' && formattedEndHour !== 12) {
    formattedEndHour += 12;
  }

  if (startPeriod === 'AM' && formattedStartHour === 12) {
    formattedStartHour = 0;
  }

  if (endPeriod === 'AM' && formattedEndHour === 12) {
    formattedEndHour = 0;
  }

  const formattedStartHourStr = String(formattedStartHour).padStart(2, '0');
  const formattedEndHourStr = String(formattedEndHour).padStart(2, '0');
  const formattedStartMinutesStr = startMinutes.padStart(2, '0');
  const formattedEndMinutesStr = endMinutes.padStart(2, '0');

  return `${formattedStartHourStr}:${formattedStartMinutesStr} - ${formattedEndHourStr}:${formattedEndMinutesStr}`;
}

export const formatSingleHourTo12Hour = (time: string) => {
  const [hour, minutes] = time.split(':');

  let formattedHour = parseInt(hour, 10);
  let period = 'AM';

  if (formattedHour === 0) {
    formattedHour = 12;
  } else if (formattedHour === 12) {
    period = 'PM';
  } else if (formattedHour > 12) {
    formattedHour -= 12;
    period = 'PM';
  }

  return `${formattedHour}:${minutes} ${period}`;
}
