import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid, Typography } from '@mui/material'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import FancyInput from './FancyInput'
import { createNotification, createNotificationSave } from './../services/Services'
import { getCurrentURL, getCurrentURLComplete } from '../utils/utils'
import { useEffect, useState } from 'react'
import { useApp } from '../hooks/useApp'

const ContactForm = () => {
  const defaultValues = {
    name: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }

  const validation = Yup.object().shape({
    name: Yup.string().required('Campo es requerido'),
    lastName: Yup.string().required('Campo es requerido'),
    email: Yup.string().email('Correo electrónico inválido').required('Campo es requerido'),
    phone: Yup.string().required('Campo es requerido'),
    message: Yup.string().required('Campo es requerido')
  })
  const url = getCurrentURLComplete()
  const { reserved, iDCompany, areasData, loading,setLoading,setSuccessMsg,setErrorMsg } = useApp()

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(validation)
  })

  const onSubmit = async (data: any) => {
    console.log(data)
    try {
      setLoading && setLoading(true)
      const notificationResponse = await createNotification(data, url)
      const saveResponse = await createNotificationSave(data, iDCompany && iDCompany.body.content[0].id)
      console.log(notificationResponse)
      console.log(saveResponse)
      setLoading && setLoading(false)
      if (notificationResponse?.status === 200 && saveResponse?.status === 200) {
        setSuccessMsg && setSuccessMsg(t("messageSentSuccessfully")); 
      }
      
    } catch (error) {
      setErrorMsg && setErrorMsg(t("errorSendingMessage"));
      setLoading && setLoading(false)
      
    }
  } 

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4" align="center" gutterBottom color="primary.main" fontWeight={700}>
        Contáctanos
      </Typography>
      <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
        <Grid item xs={6}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FancyInput
              label={t('name')}
              type="text"
                {...field}
                error={errors.name && Boolean(errors.name)}
                helperText={errors.name && errors.name.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <FancyInput
                  label={t('lastName')}
                type="text"
                {...field}
                error={errors.lastName && Boolean(errors.lastName)}
                helperText={errors.lastName && errors.lastName.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FancyInput
              label={t('email')}
              type="email"
                {...field}
                error={errors.email && Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <FancyInput
              label={t('phone')}
              type="text"
                {...field}
                error={errors.phone && Boolean(errors.phone)}
                helperText={errors.phone && errors.phone.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <FancyInput
              label={t('message')}
                type="text"
                multiline
                rows={3}
                {...field}
                error={errors.message && Boolean(errors.message)}
                helperText={errors.message && errors.message.message}
              />
            )}
          />
        </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            sx={{
              marginX: 'auto',
              marginTop: '40px'
            }}
          >
            Enviar
          </Button>
      </Grid>
    </form>
  )
}

export default ContactForm
