import React from "react";
import { FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import CountryFlag from "./CountryFlag";
import { useTranslation } from "react-i18next";

interface CountryOption {
  id: number;
  name: string;
  areaCode: string;
  flag: string | null;
  userCreated: string;
  userModified: string;
}

interface Props {
  value: number | null;
  options: { content: CountryOption[] } | null;
  setFormData: (...data: any[]) => void;
  disabled : boolean;
  formData: any;
  error?: string;
  selectedCountry: CountryOption | null;
  setSelectedCountry: (country: CountryOption | null) => void;
}

const CountrySelector: React.FC<Props> = ({ value, options, error, setFormData, selectedCountry, setSelectedCountry, formData, disabled }) => {
  const { t } = useTranslation()
  // Inicializar selectedCountry basado en value y options al montar el componente
  React.useEffect(() => {
    if (value && options?.content) {
      const parsedValue = typeof value === "string" ? parseInt(value) : value;
      const country = options.content.find(option => parseInt(option.areaCode) === parsedValue);
      setSelectedCountry(country || null);
    }
  }, [value, options]);

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    const selectedValue = event.target.value;
    const parsedValue = typeof selectedValue === "string" ? parseInt(selectedValue) : selectedValue;

    // Buscar el país seleccionado en base al ID
    const country = options?.content.find(option => option.id === parsedValue);

    // Actualizar el estado selectedCountry con el país seleccionado
    setSelectedCountry(country || null);
    setFormData({ ...formData, country: country?.areaCode });
  };

  if (!options || !options.content) {
    return (
      <FormControl fullWidth>
        <Select />
      </FormControl>
    );
  }

  return (
    <FormControl fullWidth error={!!error}>
      <Select
      disabled= {disabled}
        id="country-select"
        title=""
        value={value || ""}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected) => {
          if(!selected && selectedCountry) {
            selected = selectedCountry?.id;
          }
          const lastSelectedCountry = options.content.find(option => option.id === selected);

          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {lastSelectedCountry && (
                <CountryFlag areaCode={lastSelectedCountry.areaCode} size={20} />
              )}
              {lastSelectedCountry
                ? `${lastSelectedCountry.name} (${lastSelectedCountry.areaCode})`
                : t("selectCountry")}
            </div>
          );
        }}
      >
        <MenuItem value="">{t("selectCountry")}</MenuItem>
        {options.content.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option && (
              <CountryFlag areaCode={option.areaCode} size={20} />
            )}
            {option.name} ({option.areaCode})
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{t("requiredField")}</FormHelperText>}
    </FormControl>
  );
};

export default CountrySelector;
