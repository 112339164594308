export function formatPhoneNumber(value: string) {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength > 15) {
    // Si el número excede los 15 dígitos, cortarlo a 15
    return phoneNumber.slice(0, 15);
  }

  // Retornar el número sin guiones
  return phoneNumber;
}

export function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email)
}
