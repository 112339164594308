import { Email, Phone } from '@mui/icons-material';
import { Box, Container, Grid, Link, Stack, Typography, useTheme } from '@mui/material';
import Socials from '../components/Socials';
import { useApp } from '../hooks/useApp';

const Footer = () => {
  const theme = useTheme();
  const { companyData } = useApp();

  return (
    <>
      <Box
        pt={2}
        pb={1}
        sx={{
          backgroundColor: theme.palette.common.white,
          position: 'relative',
          borderTop: `2px solid ${theme.palette.primary.main}`
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <img src={companyData.logofooter} alt="logo" width={50} />
                <Typography
                  variant="caption"
                  fontSize={12}
                >
                  Powered by{' '}
                  <Link
                    href="https://bitcode-enterprise.com"
                    target="_blank"
                    rel="noreferrer"
                    underline="none"
                    fontWeight="regular"
                    color="primary"
                  >
                    BitCode Enterprise
                  </Link>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Socials socials={companyData.socials} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Stack
                sx={{
                  width: { md: 'fit-content' },
                  marginLeft: { md: 'auto' }
                }}
              >
                {companyData.phone !== '' && (
                  <Stack direction={'row'} spacing={1}>
                    <Phone color="primary" fontSize="small" />
                    <Typography variant="caption" color="primary">
                      {companyData.phone}
                    </Typography>
                  </Stack>
                )}
                {companyData.email !== '' && (
                  <Stack direction={'row'} spacing={1} color="primary">
                    <Email color="primary" fontSize="small" />
                    <Typography variant="caption" color="inherit" fontSize={13}>
                      <Link href={`mailto:${companyData.email}`} rel="noreferrer" target="_blank">
                        {companyData.email}
                      </Link>
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
