import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as tripadvisor } from '../assets/icons/tripadvisor.svg';

const CustomSvgIcon = ({ component: Component, ...props } : any) => {
  return (
    <SvgIcon component={Component} {...props} />
  );
};

// export const Logo = () => {
//   return (
//     <CustomSvgIcon component={logo} viewBox='0 0 360 360' sx={{ fontSize: '8rem', width: 150, mb: 4 }} />
//   );
// };

export const TripAdvisorIcon = () => {
  return (
    <SvgIcon component={tripadvisor} />
  );
};


export default CustomSvgIcon;
