import { useState, useEffect } from 'react'
import {
  Divider,
  Typography,
  Stack,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  ImageListItem,
  styled
} from '@mui/material'
import TotalToPay from '../../components/TotalToPay'
import { getHoursToBooking, sortHours, formatTo12Hour, convertTo24HourFormat, formatSingleHourTo12Hour } from '../../utils/FilterHours'
import dayjs from 'dayjs'
import { formDataInit } from '../../utils/data'
import TextInformation from '../../components/TextInformation'
import { getImages, getUrlImage } from '../../services/Services'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/navigation'
import 'swiper/css'
import { Navigation, Mousewheel } from 'swiper'
import Lightbox from 'yet-another-react-lightbox'
import Progress from '../../components/Progress'
import { useTranslation } from 'react-i18next'
import '../../i18n'
import { useApp } from '../../hooks/useApp'

const LightboxStyled = styled(Lightbox)(({ theme }) => ({
  '& .yarl__container': {
    backgroundColor: '#000000b0'
  },
  '& img': {
    //border: '3px solid white',
    width: '600px !important',
    height: '400px !important',
    maxWidth: '600px !important',
    maxHeight: '400px !important'
  }
}))

interface DetailSchedulesType {
  location: string
  date: string
  time: string[]
  fullDay: boolean
  initTime: string
  endDate: string
  nameColegial?: string
}

const DetailSchedules = ({
  location,
  date,
  fullDay,
  initTime,
  endDate,
  time: propTime,
  nameColegial
}: DetailSchedulesType) => {
  const { t } = useTranslation()
  const { iDCompany } = useApp()

  console.log(propTime)

  return (
    <>
      <Typography variant="h3" align="center" mb={3} pt={4}>
        {location}
      </Typography>
      <Divider />
      <Grid container py={4} justifyContent="space-evenly" spacing={{ xs: 2, sm: 4 }}>
        <Grid item sm={4}>
          <Typography variant="body1" gutterBottom>
            <b> {t('date')}: </b>
          </Typography>
          {nameColegial !== '' && (
            <Typography variant="body1" gutterBottom>
              <b>{t('name')}: </b>
            </Typography>
          )}
          <Typography variant="body1">
            <b>{t('hourRanges')}: </b>
            {fullDay}
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <Typography variant="body1" gutterBottom>
            {dayjs(date).format('DD/MM/YYYY')}
          </Typography>
          {nameColegial !== '' && (
            <Typography variant="body1" gutterBottom>
              {nameColegial}
            </Typography>
          )}

          {fullDay ? (
            <Typography variant="body1">
              {formatSingleHourTo12Hour(initTime)} - {formatSingleHourTo12Hour(endDate)}
            </Typography>
          ) : (
            sortHours(propTime).map((hour: string) => (
              <Typography variant="body1" key={hour}>
                {formatTo12Hour(hour)}
              </Typography>
            ))
          )}
        </Grid>
      </Grid>
    </>
  )
}

const Information = ({
  handleBack,
  handleNext,
  formData,
  setHiddenCart,
  cartData,
  setFormData,
  setCartData,
  areasData,
  colegial,
  setActiveStep,
  loading,
  currency,

}: any) => {
  const { t } = useTranslation()
  const { iDCompany } = useApp()
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'))
  const isMd = useMediaQuery(theme.breakpoints.down('md'))
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  const areaDetail = areasData?.body[formData.area]?.areaDetailList.find(
    (item: any) => item.description === formData.desc
  )
  const [urlImg, setUrlImg] = useState<string[] | undefined>(
    formData.photoGallery ? formData.photoGallery : undefined
  )
  const [open, setOpenLightbox] = useState<boolean>(false)
  const [indexImg, setIndexImg] = useState<number>(0)

  console.log(areaDetail, 'areaDetail')
  const priceBase = formData.fullDay
    ? (formData.isColegial ? areaDetail?.associatedDayAmount : areaDetail?.generalDayAmount)
    : (formData.isColegial ? areaDetail?.associatedAmount : areaDetail?.publicAmount);

  useEffect(() => {
    console.log(areaDetail)
  }, [])

  useEffect(() => {
    console.log(areasData, 'areasData')
    console.log(formData, 'formData')
    console.log(areasData?.body[formData.area]?.areaDetailList[formData.desc], 'areaDetail')
    console.log(cartData, 'cartData')
  }, [areasData])

  const getLogo = async (idArea: string) => {
    //const logo = await getImages('1', idArea)
    const logo = await getImages(iDCompany && iDCompany.body.content[0].id, idArea)

    const searchLogo = logo?.body.find((item: any) => item.idDocumentCategory.name === 'Logos')

    if (searchLogo) {
      // Usar la función getUrlImage para obtener la URL del logo
      return await getUrlImage(searchLogo.id)
    } else {
      // Si no se encuentra el logo, retornar una URL predeterminada o null
      return null
    }
  }

  const amountBeforeCart = () => {
    let amount = 0
    if (formData.fullDay) {
      if (formData.isColegial) {
        amount = areaDetail.associatedDayAmount || 0 + cartData.allAmount
      } else {
        amount = areaDetail?.generalDayAmount || 0 + cartData.allAmount
      }
    } else {
      if (areaDetail?.calculatePrice) {
        amount = priceBase * formData.hours.length + cartData.allAmount
      } else {
        amount = priceBase + cartData.allAmount
      }
    }
    return amount
  }

  useEffect(() => {
    document.documentElement.scrollTop = 0
  }, [])

  console.log(formData)

  const addInCart = async () => {
    const getHours = getHoursToBooking(formData.hours)
    console.log(getHours)
    const allBookings: any = cartData.allBookings.length > 0 ? cartData.allBookings : []
    let allAmount = cartData.allAmount ? cartData.allAmount : 0
    let lastAmount = 0
    const logo = await getLogo(areasData?.body[formData.area].id)

    if (formData.fullDay) {
      allAmount += formData.isColegial
        ? areaDetail.associatedDayAmount
        : areaDetail.generalDayAmount
      lastAmount += formData.isColegial
        ? areaDetail.associatedDayAmount
        : areaDetail.generalDayAmount

      allBookings.push({
        // logo: areasData.body[formData.area].logo,
        logo: logo,
        areaDetailId: areaDetail.id,
        areaAmount: formData.isColegial
          ? areaDetail.associatedDayAmount
          : areaDetail.generalDayAmount,
        totalHours: 0,
        startDate: formData.isWeekend ? areaDetail.startTimeWeekend : areaDetail.startTime,
        endDate: formData.isWeekend ? areaDetail.endTimeWeekend : areaDetail.endTime,
        bookingDay: formData.bookingDay,
        userEmail: formData.isColegial ? colegial.email : formData.userEmail,
        userPhone: formData.isColegial ? colegial.phone : formData.userPhone,
        userName: formData.isColegial
          ? colegial.name
          : `${formData.userName} ${formData.userLastName}`,
        areaName: formData.desc,
        fullDay: formData.fullDay,
        userCreated: areaDetail.userCreated
      })
    } else {
      getHours.forEach((booking: any, i: any) => {
        const filter = allBookings.filter((item: any) => item.bookingDay === formData.bookingDay)
        if (areaDetail?.areaType === 2) {
          if (filter.length === 0) {
            allAmount += priceBase
            lastAmount += priceBase
          }
        } else {
          if (areaDetail?.calculatePrice) {
            allAmount += priceBase * booking.totalHours
            lastAmount += priceBase * booking.totalHours

          }else {
            allAmount += priceBase 
            lastAmount += priceBase 

          }

        }

        console.log(areasData, 'areasData')
        allBookings.push({
          logo: logo,
          areaDetailId: areaDetail.id,
          areaAmount: areaDetail?.calculatePrice ? priceBase * booking.totalHours : priceBase,
          totalHours: booking.totalHours,
          startDate: booking.startDate,
          endDate: booking.endDate,
          bookingDay: formData.bookingDay,
          userEmail: formData.isColegial ? colegial.email : formData.userEmail,
          userPhone: formData.isColegial ? colegial.phone : formData.userPhone,
          userName: formData.isColegial
            ? colegial.name
            : `${formData.userName} ${formData.userLastName}`,
          areaName: formData.desc,
          fullDay: formData.fullDay,
          areaType: formData.areaType,
          userCreated: areaDetail.userCreated
        })
      })
    }

    console.log(allBookings, 'allBookings')

    setCartData({
      allAmount: formData.BookingAdmin ? 0 : allAmount,
      allBookings,
      lastItems: getHours.length,
      lastAmount
    })
  }

  const clickNextCart = () => {
    addInCart()
    setHiddenCart(false)
    setActiveStep(0)

    const setFormDataInit = formDataInit

    if (formData.isColegial) {
      setFormDataInit.isColegial = true
      setFormDataInit.nroColegial = formData.nroColegial
    }
    setFormData(setFormDataInit)
  }

  const handleLightbox = (index: any) => {
    setOpenLightbox(true)
    setIndexImg(index)
  }

  const urlImgs = urlImg?.map((img) => {
    return {
      src: img
    }
  })

  return (
    <>
      {loading ? (
        <Progress />
      ) : areasData?.status === 200 ? (
        <>
          <Grid container columnSpacing={4} sx={{ height: '100%' }}>
            <Grid
              item
              xs={12}
              lg={8}
              sx={{
                borderRight: {
                  lg: '1px solid'
                },
                pr: 3,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Stack>
                {(isMd || isXl) && <TextInformation title={`${t('bookingInformation')}`} />}
                <DetailSchedules
                  location={formData.desc}
                  date={formData.bookingDay}
                  time={formData.fullDay ? t('allDay2') : formData.hours}
                  fullDay={formData.fullDay}
                  initTime={formData.isWeekend ? areaDetail?.startTimeWeekend : areaDetail?.startTime}
                  endDate={formData.isWeekend ? areaDetail?.endTimeWeekend : areaDetail?.endTime}
                  /* initTime={areaDetail?.startDate}
                  endDate={areaDetail?.endDate} */
                  nameColegial={colegial?.name ?? ''}
                />
                <TotalToPay
                  amount={colegial.BookingAdmin ? 0 : amountBeforeCart()}
                  currency={currency}
                />
              </Stack>
              <Stack
                direction={isXs ? 'column-reverse' : 'row'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={2}
                py={5}
              >
                <Button variant="contained" onClick={handleBack}>
                  {t('back')}
                </Button>
                <Button variant="contained" onClick={clickNextCart} sx={{ ml: 4 }}>
                  {t('addToCart')}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    colegial.BookingAdmin ? setActiveStep(5) : handleNext()
                    addInCart()
                    setHiddenCart(true)
                  }}
                  sx={{ ml: 4 }}
                >
                  {formData.BookingAdmin ? t('goToConfirmation') : t('continueToPayment')}
                </Button>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              sx={{
                overflow: 'hidden',
                pt: '20px!important',
                pb: '15px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Stack
                sx={{
                  maxHeight: {
                    lg: '52vh'
                  }
                }}
              >
                {formData.area &&
                  (urlImg !== undefined && urlImg?.length > 2 ? (
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={1}
                      navigation
                      mousewheel={true}
                      direction={'horizontal'}
                      modules={[Navigation, Mousewheel]}
                      breakpoints={{
                        425: {
                          slidesPerView: 2,
                          direction: 'horizontal'
                        },
                        600: {
                          slidesPerView: 3,
                          direction: 'horizontal'
                        },
                        1201: {
                          slidesPerView: 3,
                          direction: 'vertical'
                        }
                      }}
                    >
                      {urlImg &&
                        urlImg?.map((img, i) => (
                          <SwiperSlide key={i}>
                            <img alt="name" src={img} onClick={() => handleLightbox(i)} />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  ) : (
                    urlImg &&
                    urlImg?.map((img, i) => (
                      <div key={i}>
                        <img
                          alt="name"
                          height="175px"
                          src={img}
                          onClick={() => handleLightbox(i)}
                          className="img-without-slider"
                        />
                      </div>
                    ))
                  ))}
              </Stack>
            </Grid>
          </Grid>
          <LightboxStyled
            open={open}
            index={indexImg}
            close={() => setOpenLightbox(false)}
            slides={urlImgs}
            carousel={{ finite: true }}
            controller={{ closeOnBackdropClick: true }}
          />
        </>
      ) : (
        <Typography variant="h4" color="error" align="center" fontWeight={600} my={20}>
          {areasData
            ? `error ${areasData?.status}: ${areasData?.statusText}`
            : t('error3Associated')}
        </Typography>
      )}
    </>
  )
}

export default Information
