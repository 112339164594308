import React from 'react';
import { Box } from '@mui/material';

const Logo = ({ src, alt, onClick }: any) => {
  const calculateMaxHeight = () => {
    // Obtener el alto máximo disponible para el logo (el 90% del ancho de la ventana)
    const maxHeight = window.innerHeight * 0.11;

    return maxHeight;
  };

  console.log(src);


  return (
    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    gap={2}

    onClick={onClick}
    sx={{
      cursor: 'pointer',
      '& img': {
        width: 'auto', // Se ajustará dinámicamente al tamaño máximo
        height: 'auto', // Se ajustará proporcionalmente al ancho
        maxHeight: calculateMaxHeight(),
        marginTop: '-0.5rem',
        marginBottom: '-0.5rem',
      },
    }}
  >
    <img src={src} alt={alt}/>
  </Box>
  );
};

export default Logo;
