import TotalToPay from '../../components/TotalToPay'
import TextInformation from '../../components/TextInformation'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Link,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  ImageListItem
} from '@mui/material'
import DetailSchedules from '../../components/DetailSchedules'
import dayjs from 'dayjs'
import { apiPayment, createBooking } from '../../services/Services'
import { useEffect, useState } from 'react'
import { cartDataInit, colegialDataInit, formDataInit } from '../../utils/data'
import Progress from '../../components/Progress'
import { packPayment } from '../../utils/packPayment'
import { truncateTarjeta } from '../../utils/commons'
import { HighlightOff } from '@mui/icons-material'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/navigation'
import 'swiper/css'
import { Navigation, Mousewheel } from 'swiper'
import Lightbox from 'yet-another-react-lightbox'
import { useApp } from '../../hooks/useApp'
import { useTranslation } from 'react-i18next'
import '../../i18n'
import { getHoursToBooking, sortHours, formatTo12Hour, convertTo24HourFormat } from '../../utils/FilterHours'
import { features } from 'process'

// month: 8,
// year: 24
// cardNumber: '5542578313740523',
// cvc: '285'

const BoxStyled = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: ' 1fr 1fr',
  alignItems: 'center',
  width: '100%',
  marginTop: 25,
  gap: 8,
  marginBottom: 10
}))

const LightboxStyled = styled(Lightbox)(({ theme }) => ({
  '& .yarl__container': {
    backgroundColor: '#000000b0'
  },
  '& img': {
    //border: '3px solid white',
    width: '600px !important',
    height: '400px !important',
    maxWidth: '600px !important',
    maxHeight: '400px !important'
  }
}))

const Confirmation = ({
  handleBack,
  formData,
  cartData,
  colegial,
  setActiveStep,
  setCartData,
  setColegial,
  setFormData,
  setHiddenCart,
  payOrder,
  currency,
}: any) => {
  const { t } = useTranslation()
  const { setReserved, iDCompany, setErrorMsg } = useApp()
  const [response, setResponse] = useState<any>(undefined)
  const [loading, setLoading] = useState(false)
  const [resultGDataCollector, setResultGDataCollector] = useState<any>(undefined)
  const [responsePayment, setResponsePayment] = useState<any>(undefined)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'))
  const [blockReserve, setBlockReserve] = useState<boolean>(false)

  const [urlImg, setUrlImg] = useState<string[] | undefined>(
    formData.photoGallery ? formData.photoGallery : undefined
  )
  const [open, setOpenLightbox] = useState<boolean>(false)
  const [indexImg, setIndexImg] = useState<number>(0)

  console.log(cartData)
  useEffect(() => {
    document.documentElement.scrollTop = 0
    // @ts-ignore
    const GDataCollector = window.GDataCollector
    GDataCollector.init('sand', null, function (params: any) {
      params.setupCallback({
        'collect-end': function (params: any) {
          console.log('SessionId', params.MercSessId)
          setResultGDataCollector(params.MercSessId)
        },
        'collect-begin': function () {
          console.log('collection has started')
        }
      })
      params.collectData()
    })
  }, [])
  
  const apiReservation = async (reference?: string) => {
    console.log(reference)
    console.log(formData)
    const dataToSend: any = {
      name: formData.isColegial ? colegial.name : formData.userName,
      lastName: formData.isColegial ? '' : formData.userLastName,
      email: formData.isColegial ? colegial.email : formData.userEmail,
      phone: formData.isColegial ? colegial.phone : formData.userPhone,
      totalAmount: cartData.allAmount || 0,
      payMethod: formData.payMethod,
      reference: formData.cardNumber ? 12345 : null,
      cardNumber: formData.cardNumber ? '*****' + formData.cardNumber.replace(/\s/g, '').slice(-4) : "",
      cardName: formData.cardNumber ? formData.cardUserName : "",
      observations: formData.generalObservations

    }

    if (formData.isColegial) {
      dataToSend.associatedNumber = formData.nroColegial
    }

    dataToSend.bookingDetailList = cartData.allBookings.map((item: any, i: any) => ({
      idAreaDetail: item.areaDetailId,
      totalHours: item.totalHours,
      areaAmount: item.areaAmount,
      startTime: item.startDate ? item.startDate : '07:00',
      endTime: item.endDate ? item.endDate : '10:00',
      ...(formData.spaces && { spaces: parseInt(formData.spaces) }),
      status: 2,
      bookingDay: item.bookingDay,
      fullDay: item.totalHours === 0 ? true : false
    }))

    const confirmationCreate = await createBooking(
      dataToSend,
      iDCompany && iDCompany.body.content[0].id
    )
    setResponse(confirmationCreate)
    setReserved && setReserved(true)
    document.documentElement.scrollTop = 0
  }




  const submitReservations = async () => {
    // if (formData.payMethod === 'TARJETA') {
    //   setOpenModal(true)
    //   const cards = {
    //     card: {
    //       cardHolder: formData.cardUserName,
    //       expirationDate: {
    //         month: Number(formData.cardExpirationDate.substring(0, 2)),
    //         year: Number(formData.cardExpirationDate.substring(3, 5))
    //       },
    //       cardNumber: formData.cardNumber.replaceAll(' ', ''),
    //       cvc: formData.cardCVV
    //     },
    //     tokenize: false,
    //     kountSession: resultGDataCollector
    //   }
    //   await packPayment(cards, payOrder.body.session).then(async (data) => {
    //     const resp = await apiPayment(payOrder.body.token, data)
    //     let referenceN = resp?.body.result.retrieval_ref_num as string
    //     setResponsePayment(resp)
    //     if (resp?.status === 200) {
    //       await apiReservation(referenceN)
    //       setOpenModal(false)
    //     }
    //   })
    // } else {
    try {
      setLoading && setLoading(true)
      const notificationResponse = await apiReservation()
      console.log(notificationResponse)
      setLoading && setLoading(false)
    } catch (error) {
      setErrorMsg && setErrorMsg('Error al enviar la notificación');
      setLoading && setLoading(false)

    }



  }

  useEffect(() => {
    console.log(loading)
  }, [setLoading])

  const cleanData = () => {
    setCartData(cartDataInit)
    setActiveStep(0)
    setColegial(colegialDataInit)
    setFormData(formDataInit)
    setReserved && setReserved(false)
  }

  const backAdmin = () => {
    if (formData.area === '') {
      setActiveStep(0)
      setHiddenCart(false)
    } else {
      const oldData = cartData.allBookings
      oldData.splice(-cartData.lastItems)
      setCartData({
        allBookings: oldData
      })
      setActiveStep(2)
    }
  }

  const handleLightbox = (index: any) => {
    setOpenLightbox(true)
    setIndexImg(index)
  }

  const urlImgs = urlImg?.map((img) => {
    return {
      src: img
    }
  })

  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        lg={8}
        sx={{
          borderRight: {
            lg: '1px solid'
          },
          pr: 3,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {!response ? (
          <>
            <Stack>
              <TextInformation title={`${t('bookingConfirmation')}`} />
              {formData.isColegial ? (
                <BoxStyled>
                  <Typography fontWeight={'bold'}>{t('nameAndSurname')}:</Typography>
                  <Typography className="textLeft">{colegial.name}</Typography>
                  <Typography fontWeight={'bold'}>{t('email')}:</Typography>
                  <Typography>{colegial.email}</Typography>
                  <Typography fontWeight={'bold'}>{t('phone')}:</Typography>
                  <Typography>{colegial.phone}</Typography>
                </BoxStyled>
              ) : (
                <BoxStyled>
                  <Typography fontWeight={'bold'}>{t('name')}:</Typography>
                  <Typography className="textLeft">{formData.userName}</Typography>
                  <Typography fontWeight={'bold'}>{t('lastName')}:</Typography>
                  <Typography>{formData.userLastName}</Typography>
                  <Typography fontWeight={'bold'}>{t('email')}:</Typography>
                  <Typography>{formData.userEmail}</Typography>
                  <Typography fontWeight={'bold'}>{t('phone')}:</Typography>
                  <Typography>{formData.userPhone}</Typography>
                </BoxStyled>
              )}
              {cartData.allBookings.map((item: any, i: any) => (
                <DetailSchedules
                  key={i}
                  location={item.areaName}
                  date={dayjs(item.bookingDay).format('DD/MM/YYYY')}
                  initHour={item.startDate}
                  endHour={item.endDate}
                  capacity={formData.spaces ? formData.spaces : undefined}
                />
              ))}
            </Stack>
            {!colegial.BookingAdmin && (
              <>
                <Stack>
                  {formData.payMethod !== 'TARJETA' ? (
                    <BoxStyled>
                      <Typography fontWeight={'bold'}>{t('paymentMethod')}:</Typography>
                      <Typography>{formData.payMethod}</Typography>
                    </BoxStyled>
                  ) : (
                    <BoxStyled>
                      <Typography fontWeight={'bold'}>{t('paymentMethod')}:</Typography>
                      <Typography className="textLeft">{formData.payMethod}</Typography>
                      <Typography fontWeight={'bold'}>{t('cardholderName')}:</Typography>
                      <Typography>{formData.cardUserName}</Typography>
                      <Typography fontWeight={'bold'}>{t('card')}:</Typography>
                      <Typography>
                        {truncateTarjeta(formData.cardNumber.replaceAll(' ', ''))}
                      </Typography>
                    </BoxStyled>
                  )}
                </Stack>
                <Divider />
              </>
            )}
            <Stack sx={{ justifyContent: 'center', mt: 3 }}>
              <TotalToPay
              amount={colegial.BookingAdmin ? 0 : cartData.allAmount}
              currency={currency}
              />
            </Stack>
          </>
        ) : !loading ? (
          <>
            {response.status === 200 ? (
              <>
                <Typography variant="h5" align="center" mb={4} mt={4}>
                  {response.body.message === 'Reserva exitosa'
                    ? t('bookingInTheApprovalProcess')
                    : t('areaWithoutAvailableSpace')}
                </Typography>
                <Box hidden={!response.body.message.includes('exitosa')}>
                  <Typography variant="h6" align="center" sx={{ maxWidth: 800, margin: 'auto' }}>
                    {t('bookingInTheApprovalProcessText')}
                  </Typography>
                  <Typography variant="h6" align="center" sx={{ maxWidth: 800, margin: 'auto' }}>
                    {t('sendProof')}{' '}
                    <Link href={"mailto:" + (iDCompany && iDCompany.body.content[0].mail)}>
                      {iDCompany && iDCompany.body.content[0].mail}
                    </Link>
                    {/* <Link href="https://api.whatsapp.com/send?phone=99999999">
                    WhatsApp 999999999.
                  </Link>{' '} */}{' '}
                    {t('youAreIncorporated')}
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography
                variant="h5"
                align="center"
                fontWeight={600}
                color={'error'}
                mb={4}
                mt={4}
              >
                error {response.status}: {response.body.error}
              </Typography>
            )}
          </>
        ) : (
          <Progress />
        )}
        <Stack
          direction={isXs ? 'column-reverse' : 'row'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={2}
          pb={5}
          pt={2}
        >
          {!response ? (
            <>
              <Button
                variant="contained"
                //onClick={() => (colegial.BookingAdmin ? backAdmin() : handleBack())}
                onClick={() => {
                  colegial.BookingAdmin ? backAdmin() : handleBack()
                  setReserved && setReserved(false)
                }}
              >
                {t('back')}
              </Button>
              <Button
                variant="contained"
                onClick={submitReservations}
                sx={{ ml: 4 }}
                data-tdc={formData.payMethod}
                disabled={loading}
              >
                {t('booking')}
              </Button>
            </>
          ) : response.status === 200 ? (
            <Button variant="contained" onClick={cleanData}>
              {t('makeAnotherBooking')}
            </Button>
          ) : (
            <>
              <Button variant="contained" onClick={cleanData}>
                {t('makeAnotherBooking')}
              </Button>
              <Button variant="contained" sx={{ ml: 4 }} onClick={submitReservations}>
                {t('tryAgain')}
              </Button>
            </>
          )}
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          overflow: 'hidden',
          pt: '55px!important',
          pb: '15px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {!response && (
          <Stack
            sx={{
              maxHeight: {
                lg: '58vh'
              }
            }}
          >
            {formData.area &&
              (urlImg !== undefined && urlImg?.length > 2 ? (
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  navigation
                  mousewheel={true}
                  direction={'horizontal'}
                  modules={[Navigation, Mousewheel]}
                  breakpoints={{
                    425: {
                      slidesPerView: 2,
                      direction: 'horizontal'
                    },
                    600: {
                      slidesPerView: 3,
                      direction: 'horizontal'
                    },
                    1201: {
                      slidesPerView: 3,
                      direction: 'vertical'
                    }
                  }}
                >
                  {urlImg &&
                    urlImg?.map((img, i) => (
                      <SwiperSlide key={i}>
                        <img alt="name" src={img} onClick={() => handleLightbox(i)} />
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : (
                urlImg &&
                urlImg?.map((img, i) => (
                  <div key={i}>
                    <img
                      alt="name"
                      height="175px"
                      src={img}
                      onClick={() => handleLightbox(i)}
                      className="img-without-slider"
                    />
                  </div>
                ))
              ))}
          </Stack>
        )}
      </Grid>
      <LightboxStyled
        open={open}
        index={indexImg}
        close={() => setOpenLightbox(false)}
        slides={urlImgs}
        carousel={{ finite: true }}
        controller={{ closeOnBackdropClick: true }}
      />
      <Dialog open={openModal} fullWidth>
        {responsePayment && responsePayment?.status !== 200 ? (
          <DialogContent>
            <Stack alignItems={'center'} width={'100%'} my={5}>
              <HighlightOff color="error" sx={{ fontSize: 100 }} />
            </Stack>
            <Typography variant="h5" align="center" fontWeight={700} my={2}>
              {t('anErrorProcessingRequest')}
            </Typography>
            {responsePayment.body.errors ? (
              <Typography variant="body1" align="center" mb={3}>
                {responsePayment.body.errors[0][1]}
              </Typography>
            ) : (
              <Typography variant="body1" align="center" mb={3}>
                {t('pleaseTryAgainInAFewMinutes')}
              </Typography>
            )}
            <Stack alignItems={'center'} width={'100%'} mt={5} mb={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenModal(false)
                  setResultGDataCollector(undefined)
                }}
              >
                Cerrar
              </Button>
            </Stack>
          </DialogContent>
        ) : (
          <DialogContent>
            <Typography variant="h5" align="center" fontWeight={700} my={2}>
              Procesando su reserva
            </Typography>
            <Typography variant="body1" align="center" mb={3}>
              Por favor espere un momento.
            </Typography>
            <Progress size={50} padding="20px" />
          </DialogContent>
        )}
      </Dialog>
    </Grid>
  )
}

export default Confirmation
