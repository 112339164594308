import { Typography, Divider, Grid, Box } from '@mui/material'
import { DetailSchedulesType } from '../types/Components'
import { useTranslation } from 'react-i18next'
import '../i18n'
import { getHoursToBooking, sortHours, formatSingleHourTo12Hour } from '../utils/FilterHours'

const DetailSchedules = ({ location, date, initHour, endHour, capacity }: DetailSchedulesType) => {
  const { t } = useTranslation()

  const formattedInitHour = initHour !== undefined ? formatSingleHourTo12Hour(initHour) : undefined;
  const formattedEndHour = endHour !== undefined ? formatSingleHourTo12Hour(endHour) : undefined;

  return (
    <Box>
      <Divider />
      <Grid container pt={3} pb={2} justifyContent="space-between">
        <Grid item sm={6}>
          <Typography variant="body1" gutterBottom>
            <b> {t('facility')} : </b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b> {t('date')}: </b>
          </Typography>
          <Typography variant="body1">
            <b>{t('hourRanges')}: </b>
          </Typography>
          {capacity && (
            <Typography variant="body1">
              <b>{t('capacity')}: </b>
            </Typography>
          )}
        </Grid>
        <Grid item sm={6}>
          <Typography variant="body1" fontWeight={'bold'} gutterBottom>
            {location}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {date}
          </Typography>
          <Typography variant="body1">
            {formattedInitHour} - {formattedEndHour}
          </Typography>
          {capacity && (
            <Typography variant="body1">
              {capacity}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Divider />
    </Box>
  )
}

export default DetailSchedules