import UrlBase from '../urls/Urls.js'
import { geAreasDataTypes } from '../types/Services.js'

enum Method {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

const HEADERS = new Headers({
  Accept: 'application/json',
  'Content-Type': 'application/json'
})

const urlSpaceDemo = 'demo.spacemanager.io'

export const getCompanySpaces = async (url: string | undefined) => {
  try {
    const getApi = await fetch(
      `${UrlBase.app_api}/maintenance/company/filter/?page=0&size=10&url=${url !== 'localhost' ? url + '.spacemanager.io' : UrlBase.app_club_union
      }`
    )
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
  }
}

export const getSpaces = async (url: string | undefined) => {
  try {
    // const getApi = await fetch(`${api}area/findActive/?url=${url}.spacemanager.io`)
    const getApi =
      url !== 'localhost'
        ? await fetch(
          `${UrlBase.app_api}/space-manager/area/findActive/?url=${url}.spacemanager.io`
        )
        : await fetch(
          `${UrlBase.app_api}/space-manager/area/findActive/?url=${UrlBase.app_club_union}`
        )

    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
  }
}

export const reportBookingCapacity = async (
  company_id: number,
  date: string,
  area_id: number,
  area_detail_id?: number
) => {
  try {
    const url = `${UrlBase.app_api}/space-manager/booking/bookingCapacityByDate/${company_id}/${area_id}?&date=${date}${area_detail_id ? "&areaDetailId=" + area_detail_id : ""}`;

    const response = await fetch(url);
    const data = await response.json();

    console.log(data);
    return data;
  }
  catch (error) {
    throw new Error("Error al obtener reporte de capacidad");
  }
}

export const getPermissionsAssociated = async (url: string | undefined) => {
  try {
    // const getApi = await fetch(`${api}area/findActive/?url=${url}.spacemanager.io`)
    /* const getApi = await fetch(
      `${UrlBase.app_api}/maintenance/company/filterAssociated/?page=0&size=1&id=${iDCompany}`
    ) */
    console.log('url: ', url)
    const getApi =
      url !== 'localhost'
        ? await fetch(
          `${UrlBase.app_api}/maintenance/company/filterAssociated/?page=0&size=1&url=${url}.spacemanager.io`
        )
        : await fetch(
          `${UrlBase.app_api}/maintenance/company/filterAssociated/?page=0&size=1&url=${UrlBase.app_club_union}`
          //`${UrlBase.app_api}/maintenance/company/filterAssociated/?page=0&size=1&id=1`
        )

    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
  }
}

export const getHoursAvailable = async (date: any, iDCompany: geAreasDataTypes) => {
  try {
    //const getApi = await fetch(`${UrlBase.app_api}/booking/getReserved/?company_id=1`, {
    const getApi = await fetch(
      `${UrlBase.app_api}/booking/getReserved/?company_id=${iDCompany.body.id}`,
      {
        method: Method.POST,
        headers: HEADERS,
        body: JSON.stringify(date)
      }
    )
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
  }
}

export const getAsociado = async (nroAsociado?: string, url?: string | undefined) => {
  try {
    const getApi = await fetch(
      // `${urlSpace}/maintenance/associated/filterAssociated/?associatedNumber=${nroAsociado}&companyUrl=club-union.spacemanager.io`,
      url !== 'localhost'
        ? `${UrlBase.app_api}/maintenance/associated/filterAssociated/?associatedNumber=${nroAsociado}&companyUrl=${url}`
        : `${UrlBase.app_api}/maintenance/associated/filterAssociated/?associatedNumber=${nroAsociado}&companyUrl=${UrlBase.app_club_union}`,
      {
        method: Method.GET,
        headers: HEADERS
        // body: JSON.stringify({ numColegiado: nroColegial })
      }
    )
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
  }
}

export const createBooking = async (data: any, iDCompany: any) => {
  console.log('iDCompany', iDCompany)
  try {
    //const getApi = await fetch(`${api}/booking/?company_id=1`,
    const getApi = await fetch(
      `${UrlBase.app_api}/space-manager/booking/?company_id=${iDCompany}`,
      {
        method: Method.POST,
        headers: HEADERS,
        body: JSON.stringify(data)
      }
    )
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
  }
}

export const createNotification = async (data: any, url: any) => {
  try {
    //notification/reasignedAsset?url=localhost ${url}
    const getApi = await fetch(
      `${UrlBase.app_api}/maintenance/notification/reasignedAsset?url=${url}`,
      {
        method: Method.POST,
        headers: HEADERS,
        body: JSON.stringify(data)
      }
    )
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
  }
}

export const createNotificationSave = async (data: any, iDCompany: any) => {
  const requestBody = [
    {
      idCompany: iDCompany,
      name: data.name,
      lastName: data.lastName,
      mail: data.email,
      phone: data.phone,
      message: data.message,
      state: "0",
      userCreated: data.name + " " + data.lastName

    }

  ];
  try {
    const getApi = await fetch(
      `${UrlBase.app_api}/maintenance/notifications/create`,
      //maintenance/notifications/create
      {
        method: Method.POST,
        headers: HEADERS,
        body: JSON.stringify(requestBody)
      }
    )
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
  }
}

export const getPayOrder = async (data: any) => {
  try {
    const getApi = await fetch(`${UrlBase.app_api_get_order}/greenPay/getOrder`, {
      method: Method.POST,
      headers: HEADERS,
      body: JSON.stringify(data)
    })
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
  }
}

export const apiPayment = async (accessToken: string, data: any) => {
  try {
    const getApi = await fetch(`${UrlBase.app_api_payment_prod}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'liszt-token': accessToken
      },
      body: JSON.stringify(data)
    })
    const response = await getApi.json()
    return {
      status: getApi.status === 200 ? response.status : getApi.status,
      statusText: getApi.statusText,
      body: response
    }
  } catch (error) {
    console.log(error)
  }
}

export const getImages = async (companyId: string, areaId: string, areaDetailId?: string) => {
  try {
    const getApi = await fetch(
      `${UrlBase.app_api}/documents/documents?CompanyId=${companyId}&AreaId=${areaId}${areaDetailId ? `&AreaDetailId=${areaDetailId}` : ''
      }`,
      {
        method: Method.GET,
        headers: HEADERS
      }
    )
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
  }
}

export const getUrlImage = async (id: string) => {
  try {
    const getApi = await fetch(`${UrlBase.app_api}/documents/documents/imgUrl/${id}`, {
      method: 'GET',
      headers: HEADERS
    })

    if (!getApi.ok) {
      throw new Error('Failed to fetch')
    }

    let imageUrl = await getApi.text() // Leer la respuesta como texto

    // Codificar la URL si contiene espacios
    imageUrl = imageUrl.replace(/ /g, '%20')

    return imageUrl // Devolver la URL de la imagen
  } catch (error) {
    console.error(error)
    throw error // Relanzar el error para que pueda ser manejado por el llamador de la función
  }
}

export const getCountries = async (page: any): Promise<any> => {
  try {
    const getApi = await fetch(
      `${UrlBase.app_api}/maintenance/countries/list/?page=${page}&size=${1000}`,
      {
        method: Method.GET,
        headers: HEADERS
        // body: JSON.stringify({ numColegiado: nroColegial })
      }
    )
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error: any) {
    throw new Error(error)
  }
}

export const getPayMethods = async (companyId: number) => {
  try {
    const getApi = await fetch(
      `${UrlBase.app_api}/maintenance/companyCard/payMethods?companyId=${companyId}`,
      {
        method: Method.GET,
        headers: HEADERS
        // body: JSON.stringify({ numColegiado: nroColegial })
      }
    )
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error: any) {
    throw new Error(error)
  }
}
