import { CssBaseline, ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import App from './App';
import theme from './theme/theme';
import { AppProvider } from './context/AppContext';
import { useApp } from './hooks/useApp';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const AppWithThemeProvider = () => {
  const { iDCompany } = useApp();

  return (
    <ThemeProvider theme={theme(iDCompany?.body.content[0].color)}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};

root.render(
  <AppProvider>
    <AppWithThemeProvider />
  </AppProvider>
);
