import { ChangeEvent, useState } from 'react'
import { IconButton, InputAdornment, StandardTextFieldProps, TextField } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useTranslation } from 'react-i18next'

interface validationTypes {
  validate: () => boolean
  msg: string
}

interface FancyInputTypes extends StandardTextFieldProps {
  validation?: validationTypes[]
  onlyNumber?: boolean
}

const FancyInput = ({
  label,
  name,
  value,
  variant,
  required,
  validation,
  type,
  onChange,
  onlyNumber,
  ...rest
}: FancyInputTypes) => {
  const { t } = useTranslation()
  const [touched, setTouched] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const isNumber = () => (type === 'number' || onlyNumber ? onlyNumber : false)
  // const isEmail = () => type === 'email'

  const isEmptyString = (str: string) => {
    return str === ''
  }

  // function isValidEmail(email: string) {
  //   return /\S+@\S+\.\S+/.test(email)
  // }

  const stringIsOnlyDigits = (str: string) => {
    return /^[0-9]+$/.test(str)
  }

  const onChangeCustom = (e: ChangeEvent<HTMLInputElement>) => {
    setTouched(true)
    if (isNumber() && !isEmptyString(e.target.value) && !stringIsOnlyDigits(e.target.value)) return
    if (onChange) onChange(e)
  }

  const getMessageError = () => {
    if (validation) {
      for (const v of validation) {
        if (!v.validate()) return v.msg
      }
      return ''
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const requiredCondition = touched && !value && required
  const showCustomError = touched && validation && !!getMessageError()
  const hasError = requiredCondition || showCustomError

  return (
    <>
      {type !== 'password' ? (
        <TextField
          style={{ marginBottom: 0 }}
          error={hasError}
          helperText={
            requiredCondition ? t('requiredField') : showCustomError ? getMessageError() : ''
          }
          fullWidth
          variant={'outlined'}
          name={name}
          label={label}
          value={value}
          onChange={onChangeCustom}
          {...rest}
        />
      ) : (
        <TextField
          fullWidth
          style={{ marginBottom: 0 }}
          error={hasError}
          helperText={
            requiredCondition ? t('requiredField') : showCustomError ? getMessageError() : ''
          }
          variant={'outlined'}
          name={name}
          label={label}
          value={value}
          type={showPassword ? 'text' : 'password'}
          onChange={onChangeCustom}
          InputProps={{
            sx: { paddingRight: 0 },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  sx={{ backgroundColor: 'transparent' }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
          {...rest}
        />
      )}
    </>
  )
}

export default FancyInput
