import { useApp } from './hooks/useApp'
import Layout from './layouts/Layout'
import Bookings from './views/Bookings'
import { useState } from 'react'

function App() {
  const {activeTab, setActiveTab} = useApp()
  return (
    <Layout>
      <Bookings/>
    </Layout>
  )
}

export default App
