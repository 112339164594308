import { Close, ShoppingCart } from '@mui/icons-material'
import {
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Fab,
  IconButton,
  MobileStepper,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material'
import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'swiper/css'
import 'swiper/css/navigation'
import '../assets/styles.css'
import CartItem from '../components/CartItem'
import Loading from "../components/Loading"
import MessageManager from "../components/MessageManager"
import { useApp } from '../hooks/useApp'
import { getPermissionsAssociated } from '../services/Services'
import { CONSTANT } from "../utils/Constants"
import { formatNumber, formatterMoney } from '../utils/commons'
import { getCurrentURL } from '../utils/utils'
import './../i18n'
import Confirmation from './bookingSteps/Confirmation'
import Information from './bookingSteps/Information'
import Installations from './bookingSteps/Installations'
import Payments from './bookingSteps/Payments'
import Schedules from './bookingSteps/Schedules'
import UserData from './bookingSteps/UserData'

const BtnCart = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 'calc(100vh - 500px)',
  left: 'calc(100vw - 170px)',
  flexDirection: 'row',
  justifyContent: 'center',
  zIndex: 900,
  [theme.breakpoints.down('md')]: {
    top: 'unset',
    left: 'unset',
    bottom: theme.spacing(55),
    right: theme.spacing(3)
  }
}))

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export type StepsType = {
  label: string
  component: ReactNode
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ width: '100%', height: '100%' }}
    >
      {value === index && <>{children}</>}
    </Box>
  )
}

const Bookings = () => {
  const { t } = useTranslation()
  const { reserved, iDCompany, areasData, loading, successMsg, resetSuccessMsg, errorMsg, resetErrorMsg, formData,
    setFormData,
    colegial,
    setColegial,
    cartData,
    setCartData,
    disabledFullday,
    setDisabledFullday,
    payOrder,
    setPayOrder,
    haveAssociated,
    setHaveAssociated,
    activeTab,
    setActiveTab,
  } = useApp()
  //const [loading, setLoading] = useState<boolean>(true)
  //const [areasData, setAreasData] = useState<geAreasDataTypes | undefined>(undefined)
  //const [formData, setFormData] = useState<formDataInitType>(formDataInit)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  //const [colegial, setColegial] = useState(colegialDataInit)
  const [hiddenCart, setHiddenCart] = useState(true)
  //const [cartData, setCartData] = useState<cartDataTypes>(cartDataInit)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  //const [disabledFullday, setDisabledFullday] = useState<boolean>(false)
  //const [payOrder, setPayOrder] = useState<any>()
  //const [haveAssociated, setHaveAssociated] = useState(false)
  const [currency, setCurrency] = useState<string>('USD')

  const url = getCurrentURL()

  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('md'))

  useLayoutEffect(() => {
    let isMounted = true
    const asyncEffect = async () => {
      if (isMounted) {
        //const getData = await getSpaces(url)
        const haveAssociated = await getPermissionsAssociated(url)
        setHaveAssociated && setHaveAssociated(haveAssociated!!.body.content[0]?.associated)

        /* if (getData && Object.keys(getData?.body).length > 0) {
          console.log(haveAssociated!!.body.content[0]?.associated)
          setHaveAssociated(haveAssociated!!.body.content[0]?.associated)
          //setAreasData(getData)

          setLoading(false)
        } else {
          setLoading(false)
        } */
      }
    }
    asyncEffect()
    return () => {
      isMounted = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  const handleNext = () => {
    if (activeTab !== 5) {
      setActiveTab && setActiveTab((prevActiveTab) => prevActiveTab + 1)
    }
  }

  const handleBack = () => {
    setActiveTab && setActiveTab((prevActiveTab) => prevActiveTab - 1)
    //setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  useEffect(() => {
    console.log(iDCompany);


    if (iDCompany && iDCompany?.body) {

      setCurrency(iDCompany && iDCompany?.body.content[0].currencies[0].symbol)

    }
  }, [iDCompany]);



  const deleteItemCart = (id: number) => {
    let subtractionAmount = cartData.allAmount
    const filter = cartData.allBookings.filter(
      (item: any) => item.bookingDay === cartData.allBookings[id].bookingDay
    )
    if (cartData.allBookings[id].areaType === 2) {
      if (filter.length === 1) {
        subtractionAmount = cartData.allAmount - cartData.allBookings[id].areaAmount
      }
    } else {
      subtractionAmount = cartData.allAmount - cartData.allBookings[id].areaAmount
    }
    const newDataCart = cartData.allBookings.filter((index) => index !== cartData.allBookings[id])
    setCartData && setCartData({ allAmount: subtractionAmount, allBookings: newDataCart })
  }

  const steps: StepsType[] = [
    {
      label: `${t('selectInstallation')}`,
      component: (
        <Installations
          setFormData={setFormData}
          formData={formData}
          loading={loading}
          areasData={areasData}
          handleNext={handleNext}
          isFirstLoad={isFirstLoad}
          setIsFirstLoad={setIsFirstLoad}
          // setCurrency={setCurrency}
        //iDCompany={iDCompany}
        />
      )
    },
    {
      label: `${t('scheduleTheDay')}`,
      component: (
        <Schedules
          areasData={areasData}
          formData={formData}
          setFormData={setFormData}
          handleNext={handleNext}
          handleBack={handleBack}
          setColegial={setColegial}
          colegial={colegial}
          setDisabledFullday={setDisabledFullday}
          disabledFullday={disabledFullday}
          haveAssociated={haveAssociated}
          currency={currency && currency}
        />
      )
    },
    {
      label: `${t('bookingInformation')}`,
      component: (
        <Information
          handleNext={handleNext}
          handleBack={handleBack}
          formData={formData}
          areasData={areasData}
          setHiddenCart={setHiddenCart}
          setActiveStep={setActiveTab}
          setFormData={setFormData}
          setCartData={setCartData}
          cartData={cartData}
          colegial={colegial}
          currency={currency && currency}
          />
      )
    },
    {
      label: `${t('confirmationOfData')}`,
      component: (
        <UserData
        currency={currency && currency}
        areasData={areasData}
          handleNext={handleNext}
          handleBack={handleBack}
          formData={formData}
          setFormData={setFormData}
          colegial={colegial}
          cartData={cartData}
          setActiveStep={setActiveTab}
          setHiddenCart={setHiddenCart}
          setCartData={setCartData}
        />
      )
    },
    {
      label: `${t('paymentMethod')}`,
      component: (
        <Payments
        currency={currency && currency}
        areasData={areasData}
          handleNext={handleNext}
          handleBack={handleBack}
          formData={formData}
          setFormData={setFormData}
          cartData={cartData}
          colegial={colegial}
          setPayOrder={setPayOrder}
        />
      )
    },
    {
      label: `${t('confirmationAndBooking')}`,
      component: (
        <Confirmation
        currency={currency && currency}
        handleBack={handleBack}
          setActiveStep={setActiveTab}
          formData={formData}
          colegial={colegial}
          cartData={cartData}
          setCartData={setCartData}
          setColegial={setColegial}
          setFormData={setFormData}
          setHiddenCart={setHiddenCart}
          payOrder={payOrder}
          iDCompany={iDCompany}
        />
      )
    }
  ]

  return (
    <>
      {loading && <Loading />}
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
          color='primary.main'
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
          color='primary.main'
        />
      )}
      <Container
        maxWidth="xl"
        sx={{
          /* pt: 14,
        pb: 8  */
          paddingBottom: '0',
          paddingTop: '0',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box'
        }}
      >
        <Typography variant="h3" align="center" fontWeight={300} mb={3}>
          {t('makeYourBooking')}
        </Typography>
        <Box
          sx={{
            pr: { xs: 2, sm: 5 },
            borderRadius: 3,
            flexGrow: 1
          }}
          elevation={2}
          component={Paper}
          display={{ xs: 'block', sm: 'grid' }}
          gridTemplateColumns={{ xs: '1fr', md: '350px 1fr' }}
        >
          <Box
            component={Stepper}
            orientation="vertical"
            activeStep={activeTab}
            // alternativeLabel
            sx={{
              display: { xs: 'none', md: 'flex' },
              borderColor: 'divider',
              alignSelf: 'flex-start',
              marginX: 'auto',
              marginTop: '2rem',
              pb: 2
            }}
          >
            {!reserved &&
              steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                  // sx={{
                  //   display: 'flex',
                  //   flexDirection: 'row',
                  //   justifyContent: 'center',
                  //   alignItems: 'center',
                  // }}
                  >
                    <b>{step.label}</b>
                  </StepLabel>
                </Step>
              ))}
          </Box>

          <MobileStepper
            steps={6}
            activeStep={activeTab}
            variant="progress"
            // position="static"
            sx={{
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-start',
              marginX: 'auto',
              // marginTop:"2rem"
              height: '50px'
            }}
            nextButton={null}
            backButton={null}
          />

          <Stack sx={{ overflow: 'hidden', borderLeft: 1, pl: '1.5rem', height: '100%' }}>
            <CustomTabPanel value={activeTab} index={0}>
              <Installations
                // setCurrency={setCurrency}
                setFormData={setFormData}
                formData={formData}
                loading={loading}
                areasData={areasData}
                handleNext={handleNext}
                isFirstLoad={isFirstLoad}
                setIsFirstLoad={setIsFirstLoad}
              //iDCompany={iDCompany}
              />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={1}>
              <Schedules
                areasData={areasData}
                formData={formData}
                setFormData={setFormData}
                handleNext={handleNext}
                handleBack={handleBack}
                setColegial={setColegial}
                colegial={colegial}
                setDisabledFullday={setDisabledFullday}
                disabledFullday={disabledFullday}
                haveAssociated={haveAssociated}
                currency={currency && currency}
              />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={2}>
              <Information
                handleNext={handleNext}
                handleBack={handleBack}
                formData={formData}
                areasData={areasData}
                setHiddenCart={setHiddenCart}
                setActiveStep={setActiveTab}
                setFormData={setFormData}
                setCartData={setCartData}
                cartData={cartData}
                colegial={colegial}
                currency={currency && currency}
              />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={3}>
              <UserData
                areasData={areasData}
                handleNext={handleNext}
                handleBack={handleBack}
                formData={formData}
                setFormData={setFormData}
                colegial={colegial}
                cartData={cartData}
                setActiveStep={setActiveTab}
                setHiddenCart={setHiddenCart}
                setCartData={setCartData}
                currency={currency && currency}
              />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={4}>
              <Payments
                areasData={areasData}
                handleNext={handleNext}
                handleBack={handleBack}
                formData={formData}
                setFormData={setFormData}
                cartData={cartData}
                colegial={colegial}
                setPayOrder={setPayOrder}
                currency={currency && currency}
              />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={5}>
              <Confirmation
                handleBack={handleBack}
                setActiveStep={setActiveTab}
                formData={formData}
                colegial={colegial}
                cartData={cartData}
                setCartData={setCartData}
                setColegial={setColegial}
                setFormData={setFormData}
                setHiddenCart={setHiddenCart}
                payOrder={payOrder}
                currency={currency && currency}
              />
            </CustomTabPanel>
          </Stack>
        </Box>
        <BtnCart
          sx={{
            display: hiddenCart || cartData.allBookings.length === 0 ? 'none' : 'flex'
          }}
        >
          {!openDrawer && (
            <Fab
              color="primary"
              aria-label="Carrito"
              variant="extended"
              onClick={() => setOpenDrawer(true)}
            >
              <ShoppingCart fontSize="large" />
              {isSm ? (
                cartData.allBookings.length
              ) : (
                <Typography variant="caption">{cartData.allBookings.length} Pendiente</Typography>
              )}
            </Fab>
          )}
        </BtnCart>
        <Drawer
          PaperProps={{
            sx: { width: '350px', padding: 2, justifyContent: 'space-between' }
          }}
          elevation={2}
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        // hideBackdrop={true}
        >
          <Box display="flex" width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
            <Box display="flex" gap={2} alignItems="center">
              <ShoppingCart fontSize="large" color="primary" />
              <Typography variant="body2">{cartData.allBookings.length} Pendiente</Typography>
            </Box>
            <IconButton aria-label="cerrar" onClick={() => setOpenDrawer(false)}>
              <Close color="primary" fontSize="large" />
            </IconButton>
          </Box>
          <Divider />
          <Box flex={1}>
            {cartData.allBookings.map((item: any, i: any) => {
              return (
                <CartItem
                  currency={iDCompany?.body.currency ?? 'USD'}
                  key={i}
                  colegial={colegial}
                  amount={item.areaAmount}
                  date={item.bookingDay}
                  areaName={item.areaName}
                  logo={item.logo}
                  onClick={() => deleteItemCart(i)}
                  initHour={item.startDate}
                  endHour={item.endDate}
                />
              )
            })}
          </Box>
          <Button
            sx={{ justifyContent: 'space-between' }}
            disabled={cartData.allBookings.length === 0}
            variant="contained"
            onClick={() => {
              setOpenDrawer(false)
              colegial.BookingAdmin ? setActiveTab && setActiveTab(5) : setActiveTab && setActiveTab(3)
            }}
          >
            {colegial.BookingAdmin ? (
              <Box flex={1}>Ir a confirmación</Box>
            ) : (
              <>
                <span>Confirmar reserva</span>
                <span>{formatNumber(cartData.allAmount, currency)}</span>
              </>
            )}
          </Button>
        </Drawer>
      </Container>
    </>
  )
}

export default Bookings
