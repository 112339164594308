import { createTheme, responsiveFontSizes } from '@mui/material/styles';


// Function to create the hover color by the primary color

const lightenColor = (color: string, amount: number) => {
  // Parse the hex color to RGB
  const hexToRgb = (hex: string) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  // Convert RGB to HSL
  const rgbToHsl = (r: number, g: number, b: number) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h = h !== undefined ? h / 6 : 0;
    }

    return [h || 0, s, l];
  };

  // Convert HSL to RGB
  const hslToRgb = (h: number, s: number, l: number) => {
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255),
    };
  };

  const rgb = hexToRgb(color);
  if (!rgb) return color;

  const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
  hsl[2] += amount;
  hsl[2] = Math.min(Math.max(hsl[2], 0), 1); // Clamp to [0, 1]

  const newRgb = hslToRgb(hsl[0], hsl[1], hsl[2]);
  return `rgb(${newRgb.r}, ${newRgb.g}, ${newRgb.b})`;
};

const createCustomTheme = (primaryColor: string) => {
  const primaryLight = lightenColor(primaryColor || '#0A3A7A', 0.2); // Lighten the primary color

  return responsiveFontSizes(
    createTheme({
      palette: {
        primary: {
          main: primaryColor || '#0A3A7A',
          light: primaryLight,
          contrastText: '#ffffff',
        },
        secondary: {
          main: '#907316',
          dark: '#33312B',
        },
        success: {
          main: '#d32f2f',
        },
        text: {
          primary: '#2E2E2E',
        },
        common: {
          black: '#000000',
          white: '#ffffff',
        },
      },
      typography: {
        fontFamily: ['Helvetica', 'Arial'].join(','),
        button: {
          fontSize: '1rem',
          fontWeight: 700,
          fontFamily: ['Raleway', 'Helvetica', 'Arial'].join(','),
        },
        h1: {
          fontFamily: ['Raleway', 'Helvetica', 'Arial'].join(','),
        },
        h2: {
          fontFamily: ['Raleway', 'Helvetica', 'Arial'].join(','),
        },
        h3: {
          fontFamily: ['Raleway', 'Helvetica', 'Arial'].join(','),
          fontSize: '2rem',
        },
        h4: {
          fontFamily: ['Raleway', 'Helvetica', 'Arial'].join(','),
          fontSize: '1.8rem',
        },
        h5: {
          fontSize: '1.25rem',
          fontFamily: ['Raleway', 'Helvetica', 'Arial'].join(','),
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1600,
        },
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              marginBottom: 16,
              '& .MuiInput-underline:before': {
                borderBottom: '2px solid #CACACA',
              },
              '& label': {
                color: '#666666',
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            input: {
              padding: '8px!important',
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              transform: 'translate(14px, 9px) scale(1)',
            },
            shrink: {
              transform: 'translate(14px, -9px) scale(0.75)',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            containedPrimary: {
              backgroundColor: primaryColor || '#0A3A7A',
              transitionProperty: 'all',
              borderRadius: 8,
              minWidth: 170,
              '&:hover': {
                backgroundColor: primaryLight,
              },
              textTransform: 'unset',
              '&.Mui-disabled': {
                color: 'inherit!important',
                opacity: '0.5',
              },
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            select: {
              minWidth: 150,
              backgroundColor: '#ffffff',
              '& .MuiSelect-select': { padding: '14px' },
            },
          },
        },
        MuiDivider: {
          styleOverrides: {
            root: {
              height: 2,
              background: '#000000',
              opacity: 0.6,
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              justifyContent: 'flex-start',
            },
          },
        },
        MuiStepConnector: {
          styleOverrides: {
            root: {
              '& .MuiStepConnector-line': {
                minHeight: '2vh',
              },
            },
          },
        },
      },
    })
  );
};

export default createCustomTheme;
