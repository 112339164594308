import { Check } from '@mui/icons-material'
import {
  Box,
  Grid,
  ImageListItem,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import { CustomSelect } from '../../components/CustomSelect'
import Progress from '../../components/Progress'
import StepsBtn from '../../components/StepsBtn'
import TextInformation from '../../components/TextInformation'
import { useApp } from '../../hooks/useApp'
import '../../i18n'
import { getImages, getUrlImage } from '../../services/Services'
import { InstallationsTypes } from '../../types/Views'

const ImageListItemStyled = styled(ImageListItem)(({ theme }) => ({
  //overflow: 'hidden',
  cursor: 'pointer',
  '& img': {
    transition: 'all 0.5s ease',
    '&:hover': { transform: 'scale(1.07)' }
  },
  img: {
    //objectFit: 'cover',
    objectPosition: 'center'
  }
}))

const BoxSelectsStyled = styled(Box)(({ theme }) => ({
  border: `solid 2px ${grey[200]}`,
  padding: theme.spacing(5),
  margin: theme.spacing(6, 0, 3),
  width: '100%',
  maxWidth: 500,
  rowGap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    border: 'none',
    margin: 0,
    minWidth: 300,
    paddingTop: 15
  }
}))

/* const SwiperSlideItem = styled(SwiperSlide)(() => ({
  height: 'auto!important'
})) */
const LightboxStyled = styled(Lightbox)(() => ({
  '& .yarl__container': {
    backgroundColor: '#000000b0'
  },
  '& img': {
    width: '600px !important',
    height: '400px !important',
    maxWidth: '600px !important',
    maxHeight: '400px !important'
  }
}))
const Installations = ({
  loading,
  areasData,
  formData,
  setFormData,
  handleNext,
  isFirstLoad,
  setIsFirstLoad,
}: //iDCompany
  InstallationsTypes) => {
  const theme = useTheme()
  const { iDCompany } = useApp()
  const isMd = useMediaQuery(theme.breakpoints.down('md'))
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  const { t } = useTranslation()
  const [selectDescription, setSelectDescription] = useState<string[]>(
    formData.desc !== '' && formData.area !== 'PISCINA'
      ? areasData.body[formData.area]?.areaDetailList?.map((item: any) => item.description)
      : []
  )

  const [open, setOpenLightbox] = useState<boolean>(false)
  const [indexImg, setIndexImg] = useState<number>(0)
  const [urlImg, setUrlImg] = useState<string[] | undefined>(
    formData.photoGallery ? formData.photoGallery : undefined
  )

  const getPhotos = async (idArea: string, areaDetailId?: string) => {
    //console.log('iDCompany: ', iDCompany && iDCompany.body.content[0].id)
    const images = await getImages(iDCompany && iDCompany.body.content[0].id, idArea, areaDetailId)
    //const images = await getImages('1', idArea, areaDetailId)

    return images
  }

  const getUrlPhotos = async (id: string) => {
    const images = await getUrlImage(id)

    return images
  }

  useEffect(() => {
    if (isFirstLoad) {
      document.documentElement.scrollTop = 0
      setIsFirstLoad(false)
    } else {
      document.documentElement.scrollTop = 625
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const handleTest = async () => {
      const urlPhotos = await getUrlImage('1444')
      console.log('Test', urlPhotos)
    }
    handleTest()
  }, [])

  useEffect(() => {
    // Realizar un desplazamiento a la parte superior del documento
    document.documentElement.scrollTop = 0
  }, [])

  const handleChange = async (event: SelectChangeEvent, typeSelect: string) => {
    setUrlImg([])

    const value = event.target.value as string
    if (typeSelect === 'areas') {
      const photos = await getPhotos(areasData?.body[value].id)

      const removeLogoInPhotos = photos?.body.filter(
        (item: any) => item.idDocumentCategory.id === 1
      )

      const selectedArea = areasData?.body[value]
      console.log('selectedArea: ', selectedArea)
      const areaDetailList = selectedArea?.areaDetailList
      const firstAreaDetail = areaDetailList ? areaDetailList[0] : null
      const areaType = firstAreaDetail ? firstAreaDetail.areaType : ''

      const photoGalleryPromises = removeLogoInPhotos?.map(async (item: any) => {
        const urlPhotos = await getUrlImage(item.id)
        return urlPhotos
      })

      // Esperar a que todas las promesas se resuelvan
      const photoGallery = await Promise.all(photoGalleryPromises)

      setFormData &&
        setFormData({
          ...formData,
          area: value,
          desc: areaDetailList && areaDetailList.length === 1 ? areaDetailList[0].description : '',
          detailsArea:
            areaDetailList && areaDetailList.length === 1 ? areaDetailList[0].details : '',
          bookingDay: '',
          hours: [],
          areaType,
          photoGallery
        })

      setUrlImg(photoGallery)

      if (value !== '') {
        const details = areaDetailList?.map((item: any) => item.description) || []
        setSelectDescription(details)
      } else {
        setSelectDescription([])
      }
    } else {
      const selectedArea = areasData?.body[formData.area]

      const areaDetailList = selectedArea?.areaDetailList
      const selectedSubarea = areaDetailList?.find((item: any) => item.description === value)
      const idSubarea = selectedSubarea?.id || ''

      const urlsPhoto = await getPhotos(selectedArea?.id, idSubarea)

      const urlsPhotoArrayPromises = value
        ? urlsPhoto?.body.map(async (item: any) => {
          const urlPhotos = await getUrlImage(item.id)
          return urlPhotos
        })
        : urlsPhoto?.body
          .filter((item: any) => item.idDocumentCategory.id !== 1)
          .map(async (item: any) => {
            const urlPhotos = await getUrlImage(item.id)
            return urlPhotos
          })

      // Esperar a que todas las promesas se resuelvan
      const urlsPhotoArray = await Promise.all(urlsPhotoArrayPromises)

      setFormData &&
        setFormData({
          ...formData,
          desc: value,
          detailsArea: selectedSubarea?.details || '',
          photoGallery: urlsPhotoArray,
          bookingDay: '',
          hours: []
        })

      setUrlImg(urlsPhotoArray)
    }
  }

  console.log(urlImg)

  const handleLightbox = (index: any) => {
    setOpenLightbox(true)
    setIndexImg(index)
  }

  const urlImgs = urlImg?.map((img) => {
    return {
      src: img
    }
  })
  return (
    <>
      {loading ? (
        <Progress />
      ) : areasData?.status === 200 ? (
        <>
          <Grid container columnSpacing={4} sx={{ height: '100%' }}>
            <Grid
              item
              xs={12}
              lg={8}
              sx={{
                borderRight: {
                  lg: '1px solid'
                },
                pr: 3
              }}
            >
              {(isMd || isXl) && <TextInformation title={`${t('selectInstallation')}`} />}

              <Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <BoxSelectsStyled>
                  <CustomSelect
                    name="area"
                    label=""
                    disabledEmpty
                    listItems={areasData ? areasData.body?.areas : []}
                    value={formData.area}
                    onChange={(e: SelectChangeEvent) => handleChange(e, 'areas')}
                  />
                  <Box
                    hidden={formData.area === '' /* || selectDescription?.length === 1 */}
                    sx={{ mt: 3 }}
                  >
                    <CustomSelect
                      textTransform={'inherit'}
                      name="desc"
                      label=""
                      disabledEmpty
                      listItems={selectDescription ? selectDescription : []}
                      value={formData.desc}
                      onChange={(e: SelectChangeEvent) => handleChange(e, 'description')}
                    />
                  </Box>
                </BoxSelectsStyled>
              </Stack>
              <Stack
                key={formData.des}
                direction={{ xs: 'column', md: 'row' }}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ maxWidth: '100%', width: '600px', margin: '0 auto' }}
              >
                <Grid
                  container
                /* direction={{ xs: 'column', md: 'row' }}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ flexWrap: 'wrap' }} */
                >
                  {formData.detailsArea !== '' &&
                    formData.detailsArea
                      .split(/\s*,\s*/)
                      .map((item: string, i: number) => item.trim())
                      .filter(Boolean)
                      .map((item: string, i: number) => (
                        <Grid item xs={12} sm={6} lg={4}>
                          <Typography
                            variant="body1"
                            sx={{ mr: 1.5, mb: 1, display: 'flex', alignItems: 'center' }}
                            key={i}
                          >
                            <Check
                              color="primary"
                              sx={{
                                verticalAlign: 'text-bottom',
                                mr: 0.5,
                                fontSize: '1.25rem'
                              }}
                            />
                            {item.trim().replace(/^[,\s]+|[,\s]+$/g, '')}
                          </Typography>
                        </Grid>
                      ))}
                </Grid>
              </Stack>
              <StepsBtn
                disabledBack={true}
                onClickNext={handleNext}
                disabledNext={formData.area === '' || formData.desc.length === 0}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              sx={{
                overflow: 'hidden',
                pt: '20px!important',
                //pt: '45px!important',
                display: 'flex',
                flexDirection: 'column',
                pb: '15px'
              }}
            >
              <Stack
                sx={{
                  maxHeight: {
                    lg: '52vh'
                  }
                }}
              >
                {formData.area &&
                  (urlImg !== undefined && urlImg?.length > 2 ? (
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={1}
                      //loop
                      navigation
                      mousewheel={true}
                      direction={'horizontal'}
                      modules={[Navigation, Mousewheel]}
                      breakpoints={{
                        425: {
                          slidesPerView: 2,
                          direction: 'horizontal'
                        },
                        600: {
                          slidesPerView: 3,
                          direction: 'horizontal'
                        },
                        1201: {
                          slidesPerView: 3,
                          direction: 'vertical'
                        }
                      }}
                    >
                      {urlImg &&
                        urlImg?.map((img, i) => (
                          <SwiperSlide key={i}>
                            <img alt="name" src={img} onClick={() => handleLightbox(i)} />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  ) : (
                    urlImg &&
                    urlImg?.map((img, i) => (
                      <div key={i}>
                        <img
                          alt="name"
                          height="175px"
                          src={img}
                          onClick={() => handleLightbox(i)}
                          className="img-without-slider"
                        />
                      </div>
                    ))
                  ))}
              </Stack>
            </Grid>
          </Grid>
          <LightboxStyled
            open={open}
            index={indexImg}
            close={() => setOpenLightbox(false)}
            slides={urlImgs}
            carousel={{ finite: true }}
            controller={{ closeOnBackdropClick: true }}
          />
        </>
      ) : (
        <Typography variant="h4" color="error" align="center" fontWeight={600} my={20}>
          {areasData
            ? `Error ${areasData?.status}: ${areasData?.statusText}`
            : 'Error, web no tiene data cargada'}
        </Typography>
      )}
    </>
  )
}

export default Installations
