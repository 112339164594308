export const getCurrentURL = () => {
  const url = window.location.href;
  const hostname = new URL(url).hostname;
  const subdomain = hostname.split('.')[0];
  return subdomain;
};

export const getCurrentURLComplete = () => {
  const url = window.location.href;
  const hostname = new URL(url).hostname;
  return hostname;
};
