import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from 'react';

export default function FrequentQuestions() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const data = [
    {
      "question": "¿Qué es Space Manager?",
      "answer": "Space Manager es un sistema de software de suscripción que permite a empresas y clubes gestionar y facilitar reservas de instalaciones como salones de reuniones, canchas de fútbol, canchas de baloncesto, ranchos, entre otros, para sus clientes."
    },
    {
      "question": "¿Cómo funciona el proceso de reserva con Space Manager?",
      "answer": "Los usuarios pueden realizar reservas de instalaciones por un periodo de una hora, utilizando una página web personalizada para la empresa o club. Las reservas se pueden pagar con tarjeta de crédito a través de nuestro sistema seguro."
    },
    {
      "question": "¿Qué tipos de suscripciones ofrece Space Manager?",
      "answer": "Ofrecemos tres tipos de suscripciones: Básica (50 reservas por $50), Media (100 reservas por $100) y Plus (reservas ilimitadas por $250)."
    },
    {
      "question": "¿Cómo se gestiona las reservas desde el portal administrativo?",
      "answer": "El portal administrativo permite a los administradores controlar las reservas, aprobarlas, rechazarlas o incluso crear nuevas reservas manualmente."
    },
    {
      "question": "¿Space Manager es adecuado para qué tipo de empresas o clubes?",
      "answer": "Es ideal para cualquier empresa o club que posea instalaciones como salones, canchas deportivas o espacios de recreación que deseen ofrecer para reserva a sus clientes o socios."
    },
    {
      "question": "¿Cómo se manejan los pagos dentro de Space Manager?",
      "answer": "Los pagos se realizan de manera segura a través de tarjeta de crédito. Nuestro sistema procesa los pagos y asegura la transacción para ambas partes."
    },
    {
      "question": "¿Se puede personalizar la página de reservas con la marca de mi empresa?",
      "answer": "Sí, cada empresa o club puede personalizar su página de reservas con su propia marca y estilo."
    },
    {
      "question": "¿Qué medidas de seguridad utiliza Space Manager para proteger los datos de los usuarios?",
      "answer": "Utilizamos encriptación de datos y seguimos las mejores prácticas de seguridad para asegurar que la información de los usuarios y las transacciones se mantengan protegidas."
    },
    {
      "question": "¿Cómo puedo acceder al soporte técnico si tengo problemas con el software?",
      "answer": "Ofrecemos soporte técnico a través de email, teléfono y un sistema de tickets en nuestro sitio web."
    },
    {
      "question": "¿Es posible obtener una demostración o prueba gratuita de Space Manager?",
      "answer": "Sí, ofrecemos demostraciones y una prueba gratuita para que puedas ver cómo funciona Space Manager y cómo puede beneficiar a tu empresa."
    }
  ]


  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      <Typography variant="h4" align="center" color="primary.main" fontWeight={700} marginTop="-1rem" marginBottom="1.5rem">
        Preguntas frecuentes
      </Typography>

      {data.map((item, index) => (
        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ fontWeight: 700 }}>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {item.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
