import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material'
import { Link, Stack, Typography } from '@mui/material'
import { TripAdvisorIcon } from './SocialsIcon'


const Socials = ({ socials }: any) => {
  return (
    <Stack direction={'row'} spacing={3} justifyContent={'center'}>
      {Object.keys(socials).map((key, i) => {
        let icon = null
        let link = socials[key]

        switch (key) {
          case 'twitter':
            icon = <Twitter />
            break
          case 'facebook':
            icon = <Facebook />
            break
          case 'instagram':
            icon = <Instagram />
            break
          case 'youtube':
            icon = <YouTube />
            break
          case 'tripadvisor':
            icon = <TripAdvisorIcon/>
            break
          default:
            break
        }
        return (
          <Typography color="primary" key={i}>
            <Link href={link} target="_blank" color="inherit">
              {icon}
            </Link>
          </Typography>
        )
      })}
    </Stack>
  )
}

export default Socials
