import { ReactNode, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import Footer from './Footer'
import Header from './Header'
import { useApp } from '../hooks/useApp'
import Progress from '../components/Progress'

const Layout = ({
  children
}: {
  children: ReactNode
}) => {
  const { iDCompany, loading, areasData } = useApp()

  console.log('loading: ', loading)

  return (
    <>
      {loading ? (
        <Progress />
      ) : areasData?.status === 200 ? (
        <>
          <Box>
            <Header/>
            <Box
              sx={{
                backgroundColor: grey[100],
                minHeight: 'calc(100vh - 280px)',
                display: 'flex',
                paddingTop: '120px',
                marginBottom: '60px',
                boxSizing: 'content-box'
              }}
            >
              {children}
            </Box>
            <Footer />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="h4" color="error" align="center" fontWeight={600} my={20}>
            {areasData
              ? `Error ${areasData?.status}: ${areasData?.statusText}`
              : 'Error, web no tiene data cargada'}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default Layout
