import { Box, Typography, IconButton } from '@mui/material'
import { CardItemTypes } from '../types/Components'
import { formatNumber, formatterMoney } from '../utils/commons'
import { Delete } from '@mui/icons-material'
import dayjs from 'dayjs'

const CartItem = ({
  amount,
  date,
  areaName,
  logo,
  onClick,
  initHour,
  endHour,
  colegial,
  currency
}: CardItemTypes) => {
  return (
    <Box
      display="flex"
      width={'100%'}
      alignItems={'center'}
      justifyContent={'space-between'}
      my={3}
    >
      <Box display="flex" alignItems="center" gap="8px">
        <img src={logo} alt="logoArea" width="50" />
        <Box display={'block'}>
          <Typography variant="body2" noWrap maxWidth={215}>
            {areaName}
          </Typography>
          <Typography variant="body2" mb={0.3}>
            {colegial.BookingAdmin ? '' : formatNumber(amount, currency)}
          </Typography>
          <Typography variant="body2" noWrap mb={0.3}>
            {dayjs(date).format('DD/MM/YYYY')}
          </Typography>
          <Typography variant="body2">
            {initHour} - {endHour}
          </Typography>
        </Box>
      </Box>
      <IconButton aria-label="delete" onClick={onClick}>
        <Delete color="primary" />
      </IconButton>
    </Box>
  )
}

export default CartItem
